import React from 'react';
import { Modal, Tabs } from 'antd';
import { CampActionsTypes, CampContext, HouseholdInterface } from '../Camp';
import HouseholdForm from './HouseholdForm';
import MembersTable from './MembersTable';

const { TabPane } = Tabs;

const ViewHousehold = () => {
  const { state, dispatch } = React.useContext(CampContext);
  const [activeKey, setActiveKey] = React.useState('hhTab');
  const [household, setHousehold] = React.useState<HouseholdInterface>();

  const { addingOrViewingHousehold, selectedHouseholds } = state;
  const { addingOrViewing, viewingHouseholdID } = addingOrViewingHousehold;

  React.useEffect(() => {
    setActiveKey('hhTab');
  }, [viewingHouseholdID]);

  React.useEffect(() => {
    let isSubscribed = true;
    if (!viewingHouseholdID) {
      return;
    }
    const fetchedHousehold = selectedHouseholds.find((hh) => hh.id === viewingHouseholdID);
    if (!fetchedHousehold) {
      return;
    }
    if (isSubscribed) {
      setHousehold(fetchedHousehold);
    }

    return function cleanup() {
      isSubscribed = false;
    };
  }, [viewingHouseholdID, selectedHouseholds]);

  const operationTypeMessage = (
    <h4 style={{ color: '#ea5252' }}>
      {addingOrViewing === 'adding' ? (
        'Adding a new household.'
      ) : household?.needApproval ? (
        <>
          Viewing/Editing an existing household.{' '}
          <span
            style={{
              display: 'inline-block',
              width: 210,
              height: 33,
              paddingTop: 4,
              borderRadius: 4,
              textAlign: 'center',
              backgroundColor: 'darkolivegreen',
              color: 'yellow',
            }}
          >
            Household needs approval
          </span>
        </>
      ) : (household?.membersNeedApproval || 0) > 0 ? (
        <>
          Viewing/Editing an existing household.{' '}
          <span
            style={{
              display: 'inline-block',
              width: 210,
              height: 33,
              paddingTop: 4,
              borderRadius: 4,
              textAlign: 'center',
              backgroundColor: 'lightgreen',
              color: 'darkblue',
            }}
          >
            Member(s) need approval
          </span>
        </>
      ) : (
        'Viewing/Editing an existing household.'
      )}
    </h4>
  );

  const handleOk = () => {
    if (dispatch) {
      dispatch({
        type: CampActionsTypes.DONE_ADD_VIEW_HOUSEHOLD,
      });
    }
  };

  function tabChangeHandler(key: any) {
    setActiveKey(key);
  }

  return (
    <Modal
      visible={!!addingOrViewing}
      okText='Close'
      closable={false}
      onOk={handleOk}
      cancelButtonProps={{ style: { display: 'none' } }}
      width={1200}
      style={{ overflow: 'hidden' }}
    >
      <div style={{ height: '66.5vh' }}>
        <Tabs
          defaultActiveKey='hhTab'
          activeKey={activeKey}
          onChange={tabChangeHandler}
          tabBarExtraContent={operationTypeMessage}
        >
          <TabPane tab='Basic Household Data' key='hhTab'>
            <HouseholdForm />
          </TabPane>
          {addingOrViewing === 'viewing' && (
            <TabPane tab='Member Data' key='memberTab'>
              {/* {membersData.map((member) => {
              return <p key={member.id}>{member.name}</p>;
            })} */}
              <MembersTable />
            </TabPane>
          )}
        </Tabs>
      </div>
    </Modal>
  );
};

export default ViewHousehold;
