import React, { useCallback } from 'react';
import {
  Button,
  Drawer,
  Modal,
  Space,
  Table,
  Form,
  Input,
  message,
  Popconfirm,
  Select,
} from 'antd';
import { CampActionsTypes, CampContext } from '../Camp';
import { Camp, Household, Room, Ward } from '../../../models';
import { DataStore } from '@aws-amplify/datastore';

import { getReaders, getEditors } from '../CampUtilities';

import Styles from './WardsAndRooms.module.css';
import { countBy } from 'lodash';

const { Option } = Select;

interface customWardInterface extends Ward {
  roomCount: number;
}

interface customRoomInterface extends Room {
  hhCount: number;
}

const shelterTypes: { [key: string]: string } = {
  INDIVIDUAL_HOUSE: 'Ind. House',
  BARRACK: 'Barrack',
  OTHER: 'Other',
};

const WardsAndRooms = () => {
  const { state, dispatch } = React.useContext(CampContext);
  const { viewingWardsAndRooms, camp_id, refresh } = state;
  const [camp, setCamp] = React.useState<Camp>();
  const [wards, setWards] = React.useState<Array<customWardInterface>>([]);
  const [selectedWards, setSelectedWards] = React.useState<Array<string>>([]);
  const [rooms, setRooms] = React.useState<Array<customRoomInterface>>([]);
  const [selectedRooms, setSelectedRooms] = React.useState<Array<string>>([]);

  const [addOrEditWard, setAddOrEditWard] = React.useState<'add' | 'edit' | undefined>();
  const [addOrEditRoom, setAddOrEditRoom] = React.useState<'add' | 'edit' | undefined>();

  const [wardForm] = Form.useForm();
  const [roomForm] = Form.useForm();

  const fetchData = useCallback(async () => {
    if (!camp_id) {
      return;
    }
    const fetchedCamp = await DataStore.query(Camp, camp_id);
    const fetchedWards = await DataStore.query(Ward, (w) => w.campID('eq', camp_id));
    const fetchedRooms = (await DataStore.query(Room)).filter((r) =>
      fetchedWards.map((w) => w.id).includes(r.wardID)
    );
    const fetchedHouseholds = await DataStore.query(Household, (hh) => hh.campID('eq', camp_id));

    const roomCountByWard = countBy(fetchedRooms, (fr) => fr.wardID);
    const hhCountByRoom = countBy(fetchedHouseholds, (fh) => fh.roomID);

    setCamp(fetchedCamp);
    setWards(
      fetchedWards
        .map((fw) => {
          return { ...fw, roomCount: roomCountByWard[fw.id] || 0 };
        })
        .sort((a, b) => a.wardNumber.localeCompare(b.wardNumber))
    );
    setRooms(
      fetchedRooms.map((fr) => {
        return { ...fr, hhCount: hhCountByRoom[fr.id] || 0 };
      })
    );
  }, [camp_id]);

  React.useEffect(() => {
    fetchData();
  }, [camp_id, fetchData, refresh]);

  React.useEffect(() => {
    if (addOrEditWard === 'edit' && selectedWards.length > 0) {
      wardForm.setFieldsValue({
        wardNumber: wards?.find((w) => w.id === selectedWards[0])?.wardNumber,
        description: wards?.find((w) => w.id === selectedWards[0])?.description,
      });
    } else {
      wardForm.setFieldsValue({
        wardNumber: '',
        description: '',
      });
    }
  }, [addOrEditWard, wards, selectedWards, wardForm]);

  React.useEffect(() => {
    if (addOrEditRoom === 'edit' && selectedRooms.length > 0) {
      roomForm.setFieldsValue({
        roomNumber: rooms.find((r) => r.id === selectedRooms[0])?.roomNumber,
        shelterType: rooms.find((r) => r.id === selectedRooms[0])?.shelterType,
      });
    } else {
      roomForm.setFieldsValue({
        roomNumber: '',
        shelterType: '',
      });
    }
  }, [addOrEditRoom, rooms, selectedRooms, roomForm]);

  const handleModelCancel = () => {
    if (dispatch) {
      dispatch({ type: CampActionsTypes.VIEW_WARDS_AND_ROOMS, payload: false });
    }
    setAddOrEditWard(undefined);
    setAddOrEditRoom(undefined);
  };

  const hideWardDrawer = () => {
    setAddOrEditWard(undefined);
  };

  const hideRoomDrawer = () => {
    setAddOrEditRoom(undefined);
  };

  const saveWardHandler = async () => {
    const values = await wardForm.validateFields();
    try {
      if (addOrEditWard === 'add') {
        const readers = getReaders(camp_id!);
        const editors = getEditors(camp_id!);

        if (readers.length === 0 || editors.length === 1) {
          throw new Error('Wrong camp ID.');
        }

        await DataStore.save(
          new Ward({
            wardNumber: values.wardNumber,
            description: values.description,
            campID: camp_id!,
            readers: readers,
            editors: editors,
          })
        );
      } else if (addOrEditWard === 'edit') {
        const originalWard = await DataStore.query(Ward, selectedWards[0]);
        if (originalWard) {
          await DataStore.save(
            Ward.copyOf(originalWard, (updated) => {
              updated.wardNumber = values.wardNumber;
              updated.description = values.description;
            })
          );
        }
      }
      if (dispatch) {
        dispatch({ type: CampActionsTypes.REFRESH });
      }
      setAddOrEditWard(undefined);
    } catch (error) {
      console.error(`Error in saving Ward Data. ${error}`);
    }
  };

  const deleteWardHandler = async (wardID: string) => {
    const ward = wards?.find((w) => w.id === wardID);
    if (ward && ward.roomCount <= 0) {
      const wardToDelete = await DataStore.query(Ward, wardID);
      if (wardToDelete) {
        await DataStore.delete(wardToDelete);
        if (dispatch) {
          dispatch({ type: CampActionsTypes.REFRESH });
        }
      }
    } else {
      message.error(
        `Ward (${ward?.wardNumber}) is not empty. It has ${ward?.roomCount} room${
          ward!.roomCount > 1 ? 's' : ''
        }. You cannot delete Ward (${ward?.wardNumber}).`
      );
    }
  };

  const saveRoomHandler = async () => {
    const values = await roomForm.validateFields();
    try {
      if (addOrEditRoom === 'add') {
        const readers = getReaders(camp_id!);
        const editors = getEditors(camp_id!);
        const wardID = selectedWards[0];

        if (readers.length === 0 || editors.length === 1) {
          throw new Error('Wrong camp ID.');
        }

        await DataStore.save(
          new Room({
            roomNumber: parseInt(values.roomNumber),
            shelterType: values.shelterType,
            wardID: wardID,
            readers: readers,
            editors: editors,
          })
        );
      } else if (addOrEditRoom === 'edit') {
        const originalRoom = await DataStore.query(Room, selectedRooms[0]);
        if (originalRoom) {
          await DataStore.save(
            Room.copyOf(originalRoom, (updated) => {
              updated.roomNumber = parseInt(values.roomNumber);
              updated.shelterType = values.shelterType;
            })
          );
        }
      }
      if (dispatch) {
        dispatch({ type: CampActionsTypes.REFRESH });
      }
      setAddOrEditRoom(undefined);
    } catch (error) {
      console.error(`Error in saving Room Data. ${error}`);
    }
  };

  const deleteRoomHandler = async (roomID: string) => {
    const room = rooms?.find((r) => r.id === roomID);
    if (room && room.hhCount <= 0) {
      const roomToDelete = await DataStore.query(Room, roomID);
      if (roomToDelete) {
        await DataStore.delete(roomToDelete);
        if (dispatch) {
          dispatch({ type: CampActionsTypes.REFRESH });
        }
      }
    } else {
      message.error(
        `Room (${room?.roomNumber}) is not empty. It has ${room!.hhCount} household${
          room!.hhCount > 1 ? 's' : ''
        }. You cannot delete Room (${room?.roomNumber}).`
      );
    }
  };

  const roomColumns = [
    {
      title: 'Room No.',
      dataIndex: 'roomNumber',
      key: 'roomNumber',
      sorter: (a: customRoomInterface, b: customRoomInterface) => {
        if (a.roomNumber && b.roomNumber) {
          return a.roomNumber - b.roomNumber;
        } else {
          return 0;
        }
      },
    },
    {
      title: 'Shelter Type',
      dataIndex: 'shelterType',
      key: 'shelterType',
      render: (text: string) => shelterTypes[text],
      sorter: (a: customRoomInterface, b: customRoomInterface) => {
        if (a.shelterType && b.shelterType) {
          return a.shelterType.localeCompare(b.shelterType);
        } else {
          return 0;
        }
      },
    },
    {
      title: 'Household #',
      dataIndex: 'hhCount',
      key: 'hhCount',
      sorter: (a: customRoomInterface, b: customRoomInterface) => {
        return a.hhCount - b.hhCount;
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: any) => {
        return (
          <Space size={0} style={{ height: '20px' }}>
            <Button
              type='link'
              style={{ padding: 0 }}
              disabled={record.id !== selectedRooms[0]}
              onClick={() => setAddOrEditRoom('edit')}
            >
              edit
            </Button>
            <Popconfirm
              title={`Are you sure you want to delete the Room (${record.roomNumber})?`}
              onConfirm={() => deleteRoomHandler(record.id)}
            >
              <Button type='text' danger disabled={record.id !== selectedRooms[0]}>
                delete
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const wardColumns = [
    {
      title: 'Ward No.',
      dataIndex: 'wardNumber',
      key: 'wardNumber',
      sorter: (a: customWardInterface, b: customWardInterface) =>
        a.wardNumber.localeCompare(b.wardNumber),
    },
    {
      title: 'Room #',
      dataIndex: 'roomCount',
      key: 'roomCount',
      sorter: (a: customWardInterface, b: customWardInterface) => a.roomCount - b.roomCount,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: any) => {
        return (
          <Space size={0} style={{ height: '20px' }}>
            <Button
              type='link'
              style={{ padding: 0 }}
              disabled={record.id !== selectedWards[0]}
              onClick={() => setAddOrEditWard('edit')}
            >
              edit
            </Button>
            <Popconfirm
              title={`Are you sure you want to delete the Ward (${record.wardNumber})?`}
              onConfirm={() => deleteWardHandler(record.id)}
            >
              <Button type='text' danger disabled={record.id !== selectedWards[0]}>
                delete
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <Modal
      title={
        <>
          Managing Wards and Rooms in <span style={{ color: 'blue' }}>{camp?.name}</span> Camp
        </>
      }
      width={1200}
      visible={viewingWardsAndRooms}
      cancelText='Close'
      style={{ overflow: 'hidden' }}
      okButtonProps={{ style: { display: 'none' } }}
      onCancel={handleModelCancel}
    >
      <div className={Styles.wrapper}>
        <div className={Styles.wardsColumn}>
          <div>
            <h3 style={{ display: 'inline-block' }}>Wards</h3>
            <Button type='link' style={{ float: 'right' }} onClick={() => setAddOrEditWard('add')}>
              Add New Ward
            </Button>
          </div>
          <Table
            rowSelection={{
              type: 'radio',
              selectedRowKeys: selectedWards,
              onChange: (selectedKeys: any, _: any) => {
                setSelectedWards(selectedKeys);
              },
            }}
            dataSource={wards}
            columns={wardColumns}
            size='small'
            rowKey='id'
            pagination={false}
            scroll={{ y: '345px' }}
          />
          <Drawer
            title={`${addOrEditWard === 'add' ? 'Adding New' : 'Editing'} Ward Data`}
            placement='left'
            closable={false}
            onClose={hideWardDrawer}
            visible={addOrEditWard === 'add' || addOrEditWard === 'edit'}
            getContainer={false}
            style={{ position: 'absolute' }}
            width={400}
            footer={false}
          >
            <Form name='wardForm' form={wardForm} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
              <Form.Item
                label='Ward Number'
                name='wardNumber'
                rules={[{ required: true, message: 'Required!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item label='Description' name='description'>
                <Input />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button
                  onClick={hideWardDrawer}
                  style={{ marginRight: 8, display: 'inline-block' }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={saveWardHandler}
                  type='primary'
                  style={{ display: 'inline-block' }}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Drawer>
        </div>
        <div className={Styles.roomsColumn}>
          <div>
            <h3 style={{ display: 'inline-block' }}>
              Rooms ({`Ward ${wards.find((w) => w.id === selectedWards[0])?.wardNumber || '___ '}`})
            </h3>
            <Button style={{ float: 'right' }} type='link' onClick={() => setAddOrEditRoom('add')}>
              Add New Room
            </Button>
          </div>
          <Table
            rowSelection={{
              type: 'radio',
              selectedRowKeys: selectedRooms,
              onChange: (selectedKeys: any, _: any) => {
                setSelectedRooms(selectedKeys);
              },
            }}
            dataSource={rooms
              .filter((r) => selectedWards.includes(r.wardID))
              .sort((a, b) => a.roomNumber! - b.roomNumber!)}
            columns={roomColumns}
            size='small'
            rowKey='id'
            pagination={false}
            scroll={{ y: '345px' }}
          />
          <Drawer
            title={`${addOrEditRoom === 'add' ? 'Adding New' : 'Editing'} Room Data`}
            placement='right'
            closable={false}
            onClose={hideRoomDrawer}
            visible={addOrEditRoom === 'add' || addOrEditRoom === 'edit'}
            getContainer={false}
            style={{ position: 'absolute' }}
            width={400}
            footer={false}
          >
            <Form name='roomForm' form={roomForm} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
              <Form.Item
                label='Room Number'
                name='roomNumber'
                rules={[{ required: true, message: 'Required!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item label='Shelter Type' name='shelterType'>
                <Select placeholder='Select shelter type.' allowClear>
                  {Object.keys(shelterTypes).map((st) => (
                    <Option key={st} value={st}>
                      {shelterTypes[st]}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button
                  onClick={hideRoomDrawer}
                  style={{ marginRight: 8, display: 'inline-block' }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={saveRoomHandler}
                  type='primary'
                  style={{ display: 'inline-block' }}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Drawer>
        </div>
      </div>
    </Modal>
  );
};

export default WardsAndRooms;
