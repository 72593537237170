import React from 'react';
import moment from 'moment';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';

import { CampActionsTypes, CampContext } from '../Camp';
import { checkMemberEligibility } from '../CampUtilities';

import { Button } from 'antd';
import Styles from './Actions.module.css';
import { DataStore } from 'aws-amplify';
import { Camp, Member, Township, GlobalSettings } from '../../../models';

const Actions = () => {
  const { state, dispatch } = React.useContext(CampContext);
  const { selectedHouseholds: tempSelectedHouseholds, camp_id, globalSettings } = state;
  const [householdExportLoading, setHouseholdExportLoading] = React.useState(false);

  const selectedHouseholds = tempSelectedHouseholds.filter((hh) => hh.needApproval !== true);

  const exportHouseholdsHandler = async () => {
    setHouseholdExportLoading(true);
    let campName: string | undefined;
    if (camp_id) {
      campName = (await DataStore.query(Camp, camp_id))?.name;
    } else {
      campName = 'Camp Data';
    }
    const townships = await DataStore.query(Township);

    const workbook = new ExcelJS.Workbook();
    const hhSheet = workbook.addWorksheet('Households');
    const memberSheet = workbook.addWorksheet('Members');

    hhSheet.columns = [
      { header: 'Camp', key: 'campName', width: 15 },
      { header: 'Ward', key: 'wardNumber', width: 10 },
      { header: 'Room', key: 'roomNumber', width: 10 },
      { header: 'HH Head', key: 'householdHead', width: 25 },
      { header: 'Township of Origin', key: 'townshipOfOrigin', width: 15 },
      { header: 'Stay Since', key: 'staySince', width: 15 },

      { header: 'Member Count', key: 'memberCount', width: 15 },
      { header: 'Eligible', key: 'eligible', width: '10' },
      { header: 'Eligible Members', key: 'eligibleMember', width: '15' },
      { header: 'Eligibility Criterial Met', key: 'eligibilityCriteriaMet', width: '25' },
      { header: 'Ration Number', key: 'foodRationNumber', width: 15 },
      { header: 'Current Ration', key: 'currentRation', width: 15 },
      { header: 'Contact Person', key: 'contactPersonName', width: 15 },
      { header: 'Contact Phone', key: 'contactPhoneNumber', width: 15 },

      { header: 'Monthly Income', key: 'monthlyIncome', width: 15 },

      { header: 'Small Business', key: 'smallBusiness', width: 15 },
      { header: 'Loan from Bank', key: 'bankLoan', width: 15 },
      { header: 'Rent Assets', key: 'rentAssets', width: 15 },
      { header: 'Taxi', key: 'taxi', width: 15 },
      { header: 'Crop', key: 'crop', width: 15 },
      { header: 'Skilled Labor', key: 'skilledLabor', width: 15 },
      { header: 'License Butcher', key: 'licenseButcher', width: 15 },

      { header: 'Livestock', key: 'livestock', width: 15 },
      { header: 'Agri Wager', key: 'agriculturalWager', width: 15 },
      { header: 'Non Agri Wager', key: 'nonAgriWager', width: 15 },
      { header: 'Handicrafts', key: 'handicrafts', width: 15 },
      { header: 'Mining', key: 'mining', width: 15 },
      { header: 'Forest Product', key: 'forestProduct', width: 15 },
      { header: 'Remittances (in country)', key: 'remittancesInCountry', width: 15 },
      { header: 'Remittance (abroad)', key: 'remittanceAbroad', width: 15 },
      { header: 'Gift', key: 'gift', width: 15 },
      { header: 'Loan (friends/family)', key: 'loanFirendsFamily', width: 15 },
      { header: 'Loan (money lender)', key: 'loanLender', width: 15 },
      { header: 'Loan (saving group)', key: 'loanSavingGroup', width: 15 },
      { header: 'Job', key: 'job', width: 15 },
      { header: 'Resale', key: 'resale', width: 15 },
      { header: 'Domestic Work', key: 'domesticWork', width: 15 },
      { header: 'Interest', key: 'interest', width: 15 },
      { header: 'Other', key: 'others', width: 15 },
      { header: 'Other Description', key: 'otherDescription', width: 15 },
    ] as any;
    selectedHouseholds.forEach((hh) => {
      hhSheet.addRow([
        hh.campName,
        hh.wardNumber,
        String(hh.roomNumber),
        hh.householdHead,
        townships.find((tsp) => tsp.id === hh.townshipOfOriginID)?.name,
        hh.staySince,
        hh.memberCount - hh.membersNeedApproval,
        hh.eligibility,
        hh.eligibleMemberCount,
        hh.eligibilityCriteriaMet.join(', '),
        hh.foodRationNumber,
        hh.currentRation,
        hh.contactPersonName,
        hh.contactPhoneNumber,

        hh.monthlyIncome,
        hh.alt_income_sources_small_business ? 'TRUE' : '',
        hh.alt_income_sources_loan_bank ? 'TRUE' : '',
        hh.alt_income_sources_rent_assets ? 'TRUE' : '',
        hh.alt_income_sources_taxi ? 'TRUE' : '',
        hh.alt_income_sources_crop ? 'TRUE' : '',
        hh.alt_income_sources_skilled_labor ? 'TRUE' : '',
        hh.alt_income_sources_license_butcher ? 'TRUE' : '',
        hh.alt_income_sources_livestock ? 'TRUE' : '',
        hh.alt_income_sources_agri_wager ? 'TRUE' : '',
        hh.alt_income_sources_non_agri_wager ? 'TRUE' : '',
        hh.alt_income_sources_handicrafts ? 'TRUE' : '',
        hh.alt_income_sources_mining ? 'TRUE' : '',
        hh.alt_income_sources_forest_product ? 'TRUE' : '',
        hh.alt_income_sources_remittances_in_country ? 'TRUE' : '',
        hh.alt_income_sources_remittance_abroad ? 'TRUE' : '',
        hh.alt_income_sources_gift ? 'TRUE' : '',
        hh.alt_income_sources_loan_friends_family ? 'TRUE' : '',
        hh.alt_income_sources_loan_lender ? 'TRUE' : '',
        hh.alt_income_sources_loan_saving_group ? 'TRUE' : '',
        hh.alt_income_sources_job ? 'TRUE' : '',
        hh.alt_income_sources_re_sale ? 'TRUE' : '',
        hh.alt_income_sources_domestic_work ? 'TRUE' : '',
        hh.alt_income_sources_interest ? 'TRUE' : '',
        hh.alt_income_sources_others ? 'TRUE' : '',
        hh.alt_income_sources_others_description,
      ]);
    });
    for (let i = 1; i <= hhSheet.columnCount; i++) {
      hhSheet.getCell(1, i).alignment = { wrapText: true, vertical: 'top', horizontal: 'left' };
      hhSheet.getCell(1, i).fill = {
        type: 'pattern',
        pattern: 'darkTrellis',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' },
      };
      for (let j = 1; j <= hhSheet.rowCount; j++) {
        hhSheet.getCell(j, i).border = {
          top: { style: 'thin' },
          right: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
        };
      }
    }

    const firstRow = hhSheet.getRow(1);
    firstRow.height = 35;

    hhSheet.autoFilter = {
      from: 'A1',
      to: {
        row: hhSheet.rowCount,
        column: hhSheet.columnCount,
      },
    };
    hhSheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1, activeCell: 'A2' }];

    memberSheet.columns = [
      { header: 'Camp', key: 'campName', width: 15 },
      { header: 'Ward', key: 'wardNumber', width: 10 },
      { header: 'Room', key: 'roomNumber', width: 10 },
      { header: 'HH Head', key: 'householdHead', width: 25 },
      { header: 'Ration Number', key: 'foodRationNumber', width: 15 },
      { header: 'Current Ration', key: 'currentRation', width: 15 },

      { header: 'Name', key: 'name', width: 25 },
      { header: 'Age', key: 'age', width: 10 },
      { header: 'Gender', key: 'gender', width: 10 },
      { header: 'Relationship to Head', key: 'relationshipToHead', width: 20 },

      { header: 'Is Member earning?', key: 'isMemberEarning', width: 15 },

      { header: 'Chronically Ill', key: 'chronicallyIll', width: 15 },
      { header: 'Chronically Ill Description', key: 'chronicallyIllDescription', width: 15 },

      { header: 'Disabled', key: 'disabled', width: 15 },
      { header: 'Vision Disability', key: 'disabilityVision', width: 15 },
      { header: 'Hearing Disability', key: 'disabilityHearing', width: 15 },
      { header: 'Mental Disability', key: 'disabilityMental', width: 15 },
      { header: 'Intellectual Disability', key: 'disabilityIntellectual', width: 15 },
      { header: 'Brain Injury', key: 'disabilityBrainInjury', width: 15 },
      { header: 'Autism', key: 'disabilityAutism', width: 15 },
      { header: 'Physical Disability', key: 'disabilityPhysical', width: 15 },
      { header: 'Other Disability', key: 'disabilityOther', width: 15 },
      { header: 'Other Disability Description', key: 'disabilityOtherDescription', width: 15 },

      { header: 'Service Personnel', key: 'servicePersonnel', width: 15 },
      { header: 'Teacher/Nurse', key: 'teacherNurse', width: 15 },
      { header: 'Chinese', key: 'chinese', width: 15 },
      { header: 'Boarding Student', key: 'boardingStudent', width: 15 },
      { header: 'Villagers', key: 'villagers', width: 15 },
      { header: 'Married To IDP', key: 'marriedToIdp', width: 15 },
      { header: 'Priest/Pastor', key: 'priestPastor', width: 15 },
      { header: 'Deregistered IDP', key: 'deregisteredIdp', width: 15 },
      { header: 'Relocated IDP', key: 'relocatedIdp', width: 15 },
      { header: 'From Non Conflict Area', key: 'fromNonConflictArea', width: 15 },
      {
        header: 'Registered During Non Conflict Time',
        key: 'registeredDuringNonConflictTime',
        width: 15,
      },
      { header: 'Household Eligibility', key: 'eligibility', width: 15 },
      { header: 'Member Eligibility', key: 'eligibility', width: 15 },
      { header: 'Eligibility Reason', key: 'eligibilityReason', width: 20 },
    ] as any;

    const householdIdList = selectedHouseholds.map((hh) => hh.id);
    const allMembers = await DataStore.query(Member);
    const selectedMembers = allMembers.filter(
      (member) => householdIdList.includes(member.householdID) && member.needApproval !== true
    );

    selectedHouseholds.forEach((hh) => {
      const members = selectedMembers.filter((m) => m.householdID === hh.id);
      members.forEach((member) => {
        const memberIneligibility = checkMemberEligibility(member, globalSettings!);

        memberSheet.addRow([
          hh.campName,
          hh.wardNumber,
          String(hh.roomNumber),
          hh.householdHead,
          hh.foodRationNumber,
          hh.currentRation,
          member.name,
          member.age,
          member.gender,
          member.relationshipToHead,
          member.isMemberEarning,
          member.chronicallyIll,
          member.chronicallyIllDescription,
          member.disabled ? 'TRUE' : '',
          member.disabilityVision ? 'TRUE' : '',
          member.disabilityHearing ? 'TRUE' : '',
          member.disabilityMental ? 'TRUE' : '',
          member.disabilityIntellectual ? 'TRUE' : '',
          member.disabilityBrainInjury ? 'TRUE' : '',
          member.disabilityAutism ? 'TRUE' : '',
          member.disabilityPhysical ? 'TRUE' : '',
          member.disabilityOther ? 'TRUE' : '',
          member.disabilityOtherDescription,
          member.servicePersonnel ? 'TRUE' : '',
          member.teacherNurse ? 'TRUE' : '',
          member.chinese ? 'TRUE' : '',
          member.boardingStudent ? 'TRUE' : '',
          member.villagers ? 'TRUE' : '',
          member.marriedToIdp ? 'TRUE' : '',
          member.priestPastor ? 'TRUE' : '',
          member.deregisteredIdp ? 'TRUE' : '',
          member.relocatedIdp ? 'TRUE' : '',
          member.fromNonConflictArea ? 'TRUE' : '',
          member.registeredDuringNonConflictTime ? 'TRUE' : '',
          hh.eligibility,
          hh.eligibility === 'no'
            ? 'no'
            : memberIneligibility?.ineligibleState === 'yes'
            ? 'yes'
            : 'no',
          memberIneligibility?.criteriaMet!.length! > 0
            ? memberIneligibility?.criteriaMet.join(', ')
            : '',
        ]);
      });
    });

    for (let i = 1; i <= memberSheet.columnCount; i++) {
      memberSheet.getCell(1, i).alignment = { wrapText: true, vertical: 'top', horizontal: 'left' };
      memberSheet.getCell(1, i).fill = {
        type: 'pattern',
        pattern: 'darkTrellis',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' },
      };
      for (let j = 1; j <= memberSheet.rowCount; j++) {
        memberSheet.getCell(j, i).border = {
          top: { style: 'thin' },
          right: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
        };
      }
    }
    const firstRowMember = memberSheet.getRow(1);
    firstRowMember.height = 45;

    memberSheet.autoFilter = {
      from: 'A1',
      to: {
        row: memberSheet.rowCount,
        column: memberSheet.columnCount,
      },
    };
    memberSheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1, activeCell: 'A2' }];

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${campName} ${moment().format('YYYY-MM-DD h-mm a')}.xlsx`);
    setHouseholdExportLoading(false);
  };

  const addNewHouseholdHandler = () => {
    if (dispatch) {
      dispatch({ type: CampActionsTypes.ADD_HOUSEHOLD });
    }
  };

  const tempAction = async () => {
    const originalSettings = await DataStore.query(
      GlobalSettings,
      'b807ddd0-b292-4f3c-8515-d8709d7bb964'
    );
    // console.log(originalSettings);

    if (!originalSettings) return;
    await DataStore.save(
      GlobalSettings.copyOf(originalSettings, (updated) => {
        updated.individualIneligibility = [
          'servicePersonnel',
          'teacherNurse',
          'boardingStudent',
          'marriedToIdp',
          'relocatedIdp',
        ];
        updated.householdIneligibility = [
          'chinese',
          'villagers',
          'priestPastor',
          'deregisteredIdp',
          'fromNonConflictArea',
          'registeredDuringNonConflictTime',
        ];
      })
    );
    // await DataStore.save(
    //   new GlobalSettings({
    //     // ineligibleCriteria: [],
    //     editors: ['dioceseNational'],
    //   })
    // );
  };

  return (
    <>
      <Button
        className={Styles.export_buttons}
        onClick={addNewHouseholdHandler}
        // loading={householdExportLoading}
      >
        Add a new Household
      </Button>
      <Button
        className={Styles.export_buttons}
        onClick={exportHouseholdsHandler}
        loading={householdExportLoading}
      >
        Export Households
      </Button>
      {/* <Button className={Styles.export_buttons} onClick={tempAction}>
        Temporary Action
      </Button> */}
    </>
  );
};

export default Actions;
