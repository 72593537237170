import React from 'react';
import { DataStore, Hub, Auth } from 'aws-amplify';

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

// import { AmplifyAuthenticator, AmplifyGreetings, AmplifySignIn } from '@aws-amplify/ui-react';
import { Authenticator, View, useTheme, Text, Heading, Button } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange, CognitoUserInterface } from '@aws-amplify/ui-components';

import CampList from './Screens/CampList/CampList';
import Camp from './Screens/Camp/Camp';
import Styles from './App.module.css';
import { Household } from './models';

export interface campInfo {
  id: string;
  name: string;
}

function App() {
  const [authState, setAuthState] = React.useState<AuthState>();
  const [user, setUser] = React.useState<CognitoUserInterface>();
  const [displayCamp, setDisplayCamp] = React.useState<campInfo>({
    id: '',
    name: '',
  });

  // React.useEffect(() => {
  //   const subscription = DataStore.observe(Household).subscribe((msg) => {
  //     console.log(msg.opType);
  //     console.log(msg.element);
  //   });
  //   return () => {
  //     subscription.unsubscribe();
  //   };
  // }, []);

  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setAuthState(AuthState.SignedIn);
        setUser(user);
      })
      .catch(() => {
        setAuthState(AuthState.SignedOut);
        setUser(undefined);
      });
  }, []);

  // React.useEffect(() => {
  //   return onAuthUIStateChange((nextAuthState, authData) => {
  //     setAuthState(nextAuthState);
  //     setUser(authData as CognitoUserInterface);
  //   });
  // }, []);

  // const listener = React.useCallback(async (hubData) => {
  //   const { event, data } = hubData.payload;
  //   switch (event) {
  //     case 'signOut':
  //       await DataStore.clear();
  //       break;
  //   }
  // }, []);

  React.useEffect(() => {
    const listener = Hub.listen('auth', (data) => {
      // console.log('Augh Listener Changed', data.payload.data);
      switch (data.payload.event) {
        case 'signIn':
          setAuthState(AuthState.SignedIn);
          setUser(data.payload.data);
          break;
        case 'signOut':
        case 'signIn_failure':
          setAuthState(AuthState.SignedOut);
          setUser(undefined);
          ////////
          DataStore.clear();
      }
    });
    return () => {
      listener();
    };
  }, []);

  // React.useEffect(() => {
  //   Hub.listen('auth', listener);
  //   return () => Hub.remove('auth', listener);
  // }, [listener]);

  const components = {
    Header() {
      const { tokens } = useTheme();
      return (
        <View textAlign='center' padding={tokens.space.large}>
          <Heading level={4} color={tokens.colors.orange[90]}>
            Camp Information System
          </Heading>
        </View>
      );
    },
    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign='center' padding={tokens.space.large}>
          <Text color={tokens.colors.neutral['80']}>Karuna Mission Social Solidarity</Text>
        </View>
      );
    },
    SignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={6}
            color={tokens.colors.brand.primary[90]}
          >
            HARP Project Sign In
          </Heading>
        );
      },
    },
  };

  return authState === AuthState.SignedIn && user ? (
    <Router>
      <div>
        <div className={Styles.headerBar}>
          <Link className={Styles.logo} to='/' onClick={() => setDisplayCamp({ id: '', name: '' })}>
            HARP Project - Camp Data
          </Link>
          <span className={Styles.campName}>{displayCamp.name}</span>
          {/* <AmplifyGreetings username={user.username} /> */}
          <div className={Styles.signOutButtonGroup}>
            <Text className={Styles.Text}>Hello, {user.username}!</Text>
            <Button className={Styles.Button} onClick={() => Auth.signOut()}>
              Sign Out
            </Button>
          </div>
        </div>
        <div className={Styles.container}>
          <Switch>
            <Route path='/:camp_id'>
              <Camp camp_id={displayCamp.id} />
            </Route>
            <Route path='/'>
              <CampList setCamp={setDisplayCamp} />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  ) : (
    <Authenticator components={components}>
      {({ signOut, user }) => (
        <main>
          <h1>Hello {user.username}</h1>
          <button onClick={signOut}>Sign out</button>
        </main>
      )}
      {/* <AmplifySignIn headerText='HARP Project Sign In' slot='sign-in' hideSignUp></AmplifySignIn> */}
    </Authenticator>
  );
}

export default App;
