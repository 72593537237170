import React from 'react';
import { CampActionsTypes, CampContext, HouseholdInterface } from '../Camp';
import { DataStore } from 'aws-amplify';
import { intersection } from 'lodash';

import { Member, Camp, Diocese } from '../../../models';

import {
  Button,
  Space,
  Table,
  Tooltip,
  Drawer,
  Popconfirm,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Select,
  Checkbox,
} from 'antd';
import {
  checkMemberEligibility,
  getEditors,
  getMemberDisabilityLIst,
  getReaders,
} from '../CampUtilities';

const { Option } = Select;

interface extendedMemberType extends Member {
  ineligibleState: string | undefined;
  criteriaMet: string[] | undefined;
  disabilityList: string[];
}

const regularFormItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const checkboxOnlyLayout = {
  labelCol: { span: 16 },
  wrapperCol: { span: 8 },
  style: { marginBottom: 3 },
};

const checkboxOnlyLayoutLongLabel = {
  labelCol: { span: 21 },
  wrapperCol: { span: 3 },
  style: { marginBottom: 3 },
};

const MembersTable = () => {
  const [householdData, setHouseholdData] = React.useState<HouseholdInterface>();
  const { state, dispatch } = React.useContext(CampContext);
  const [members, setMembers] = React.useState<Array<extendedMemberType>>([]);
  const [addEditMember, setAddEditMember] = React.useState<'add' | 'edit' | undefined>(undefined);
  const [chronicIll, setChronicIll] = React.useState(false);
  // const [disabledMember, setDisabledMember] = React.useState(false);
  const [disabledOther, setDisabledOther] = React.useState(false);
  const [form] = Form.useForm();

  const {
    addingOrViewingHousehold,
    selectedHouseholds,
    camp_id,
    globalSettings,
    refresh,
    authUser,
  } = state;
  const { addingOrViewing, viewingHouseholdID } = addingOrViewingHousehold;

  const authUserIsHouseholdCreator =
    !!authUser.sub &&
    !!householdData &&
    !!householdData.creatorId &&
    authUser.sub === householdData.creatorId;

  const authUserIsMemberCreator = (member: Member): boolean => {
    return !!authUser.sub && !!member.creatorId && authUser.sub === member.creatorId;
  };

  const authUserIsEditor = intersection(householdData?.editors, authUser.groups).length > 0;

  React.useEffect(() => {
    let isSubscribed = true;
    if (!viewingHouseholdID) {
      return;
    }
    try {
      const fetchedHousehold = selectedHouseholds.find((hh) => hh.id === viewingHouseholdID);
      if (!fetchedHousehold) {
        if (dispatch) {
          dispatch({
            type: CampActionsTypes.DONE_ADD_VIEW_HOUSEHOLD,
          });
        }
        return;
      }
      if (isSubscribed) {
        setHouseholdData(fetchedHousehold);
      }
    } catch (err) {
      console.error('Data Store error in Edit Household');
    }

    return function cleanup() {
      isSubscribed = false;
    };
  }, [viewingHouseholdID, selectedHouseholds, refresh, dispatch]);

  React.useEffect(() => {
    if (!viewingHouseholdID) return;
    let isSubscribed = true;
    const fetchMembers = async () => {
      const fetchedMembers = await DataStore.query(Member, (m) =>
        m.householdID('eq', viewingHouseholdID)
      );

      const extendedMemberList: extendedMemberType[] = [];

      fetchedMembers.forEach((fm) => {
        extendedMemberList.push({
          ...fm,
          ineligibleState: checkMemberEligibility(fm, globalSettings!)?.ineligibleState,
          criteriaMet: checkMemberEligibility(fm, globalSettings!)?.criteriaMet,
          disabilityList: getMemberDisabilityLIst(fm),
        });
      });
      if (isSubscribed) {
        setMembers(extendedMemberList);
      }
    };
    fetchMembers();
    return function () {
      isSubscribed = false;
    };
  }, [viewingHouseholdID, globalSettings, refresh]);

  React.useEffect(() => {
    form.validateFields(['chronicallyIllDescription']);
  }, [chronicIll, form]);

  React.useEffect(() => {
    form.validateFields(['disabilityOtherDescription']);
  }, [disabledOther, form]);

  const hideMemberDrawer = () => {
    setAddEditMember(undefined);
  };

  const handleAddMember = () => {
    setAddEditMember('add');
    form.resetFields();
  };

  const handleEditMember = (member: Member) => {
    setAddEditMember('edit');
    form.setFieldsValue({
      id: member.id,
      name: member.name,
      age: member.age,
      gender: member.gender,
      relationshipToHead: member.relationshipToHead,

      isMemberEarning: member.isMemberEarning,

      chronicallyIll: member.chronicallyIll,
      chronicallyIllDescription: member.chronicallyIllDescription,

      disabled: member.disabled,
      disabilityVision: member.disabilityVision,
      disabilityHearing: member.disabilityHearing,
      disabilityMental: member.disabilityMental,
      disabilityIntellectual: member.disabilityIntellectual,
      disabilityBrainInjury: member.disabilityBrainInjury,
      disabilityAutism: member.disabilityAutism,
      disabilityPhysical: member.disabilityPhysical,
      disabilityOther: member.disabilityOther,
      disabilityOtherDescription: member.disabilityOtherDescription,

      servicePersonnel: member.servicePersonnel,
      teacherNurse: member.teacherNurse,
      chinese: member.chinese,
      boardingStudent: member.boardingStudent,
      villagers: member.villagers,
      marriedToIdp: member.marriedToIdp,
      priestPastor: member.priestPastor,
      deregisteredIdp: member.deregisteredIdp,
      relocatedIdp: member.relocatedIdp,
      fromNonConflictArea: member.fromNonConflictArea,
      registeredDuringNonConflictTime: member.registeredDuringNonConflictTime,
    });
    setChronicIll(!!member.chronicallyIll);
    setDisabledOther(!!member.disabilityOther);
  };

  const handleDeleteMember = async (memberID: string) => {
    const memberToDelete = await DataStore.query(Member, memberID);
    if (!memberToDelete) return;
    await DataStore.delete(memberToDelete);
    if (dispatch) dispatch({ type: CampActionsTypes.REFRESH });
  };

  const handleMemberFormSave = async () => {
    try {
      const values = await form.validateFields();
      if (addEditMember === 'add') {
        if (!viewingHouseholdID) return;
        if (!camp_id) return;
        const activeCamp = await DataStore.query(Camp, camp_id);
        if (!activeCamp) return;
        const activeDiocese = await DataStore.query(Diocese, activeCamp.dioceseID);
        if (!activeDiocese) return;
        const diocese_id = activeDiocese.id;

        const newMember = new Member({
          householdID: viewingHouseholdID,
          dioceseID: diocese_id,
          campID: camp_id,
          readers: getReaders(camp_id),
          editors: getEditors(camp_id),

          name: values.name,
          age: values.age,
          gender: values.gender,
          relationshipToHead: values.relationshipToHead,

          isMemberEarning: values.isMemberEarning,

          chronicallyIll: values.chronicallyIll,
          chronicallyIllDescription: values.chronicallyIllDescription,

          disabled: values.disabled,
          disabilityVision: values.disabilityVision,
          disabilityHearing: values.disabilityHearing,
          disabilityMental: values.disabilityMental,
          disabilityIntellectual: values.disabilityIntellectual,
          disabilityBrainInjury: values.disabilityBrainInjury,
          disabilityAutism: values.disabilityAutism,
          disabilityPhysical: values.disabilityPhysical,
          disabilityOther: values.disabilityOther,
          disabilityOtherDescription: values.disabilityOtherDescription,

          servicePersonnel: values.servicePersonnel,
          teacherNurse: values.teacherNurse,
          chinese: values.chinese,
          boardingStudent: values.boardingStudent,
          villagers: values.villagers,
          marriedToIdp: values.marriedToIdp,
          priestPastor: values.priestPastor,
          deregisteredIdp: values.deregisteredIdp,
          relocatedIdp: values.relocatedIdp,
          fromNonConflictArea: values.fromNonConflictArea,
          registeredDuringNonConflictTime: values.registeredDuringNonConflictTime,

          /////
          needApproval: true,
          creatorId: authUser.sub,
          creatorEmail: authUser.email,
        });

        await DataStore.save(newMember);
      } else if (addEditMember === 'edit') {
        const memberID = form.getFieldsValue(['id'])?.id;
        const originalMember = await DataStore.query(Member, memberID);
        if (!originalMember) return;
        await DataStore.save(
          Member.copyOf(originalMember, (updated) => {
            updated.name = values.name;
            updated.age = values.age;
            updated.gender = values.gender;
            updated.relationshipToHead = values.relationshipToHead;

            updated.isMemberEarning = values.isMemberEarning;

            updated.chronicallyIll = values.chronicallyIll;
            updated.chronicallyIllDescription = values.chronicallyIll
              ? values.chronicallyIllDescription
              : '';

            updated.disabled = values.disabled;
            updated.disabilityVision = values.disabilityVision;
            updated.disabilityHearing = values.disabilityHearing;
            updated.disabilityMental = values.disabilityMental;
            updated.disabilityIntellectual = values.disabilityIntellectual;
            updated.disabilityBrainInjury = values.disabilityBrainInjury;
            updated.disabilityAutism = values.disabilityAutism;
            updated.disabilityPhysical = values.disabilityPhysical;
            updated.disabilityOther = values.disabilityOther;
            updated.disabilityOtherDescription = values.disabilityOther
              ? values.disabilityOtherDescription
              : '';

            updated.servicePersonnel = values.servicePersonnel;
            updated.teacherNurse = values.teacherNurse;
            updated.chinese = values.chinese;
            updated.boardingStudent = values.boardingStudent;
            updated.villagers = values.villagers;
            updated.marriedToIdp = values.marriedToIdp;
            updated.priestPastor = values.priestPastor;
            updated.deregisteredIdp = values.deregisteredIdp;
            updated.relocatedIdp = values.relocatedIdp;
            updated.fromNonConflictArea = values.fromNonConflictArea;
            updated.registeredDuringNonConflictTime = values.registeredDuringNonConflictTime;
          })
        );
      } else {
        throw new Error('Add or Edit status has not been set.');
      }
      if (dispatch) {
        dispatch({ type: CampActionsTypes.REFRESH });
      }

      setAddEditMember(undefined);
      hideMemberDrawer();
    } catch (err) {
      console.log(err);
    }
  };

  const checkDisability = () => {
    const disabilityValues = form.getFieldsValue([
      'disabilityVision',
      'disabilityHearing',
      'disabilityMental',
      'disabilityIntellectual',
      'disabilityBrainInjury',
      'disabilityAutism',
      'disabilityPhysical',
      'disabilityOther',
    ]);
    const isMemberDisabled = Object.values(disabilityValues).some((dv) => dv);
    form.setFieldsValue({ disabled: isMemberDisabled });
  };

  const approveMember = async (memberID: string) => {
    const memberToApprove = await DataStore.query(Member, memberID);
    if (!memberToApprove) return;
    await DataStore.save(
      Member.copyOf(memberToApprove, (updated) => {
        updated.needApproval = false;
        updated.approverId = authUser.sub;
        updated.approverEmail = authUser.email;
      })
    );
    if (dispatch) dispatch({ type: CampActionsTypes.REFRESH });
  };

  const memberTableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: Member, b: Member) =>
        a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()),
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      sorter: (a: Member, b: Member) => (a.age && b.age ? a.age - b.age : 0),
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      sorter: (a: Member, b: Member) => a.gender.localeCompare(b.gender),
      render: (text: string) => <span>{GenderTypeMap[text]}</span>,
    },
    {
      title: 'Relationship',
      dataIndex: 'relationshipToHead',
      key: 'relationshipToHead',
      sorter: (a: Member, b: Member) => a.relationshipToHead.localeCompare(b.relationshipToHead),
      render: (text: string) => <span>{RelationshipTypeMap[text]}</span>,
    },
    {
      title: 'Earning',
      dataIndex: 'isMemberEarning',
      key: 'isMemberEarning',
      sorter: (a: Member, b: Member) =>
        a.isMemberEarning === b.isMemberEarning ? 0 : a.isMemberEarning ? -1 : 1,
      render: (_: any, record: Member) => (record.isMemberEarning ? 'yes' : ''),
    },
    {
      title: 'Chronic Ill',
      dataIndex: 'chronicallyIll',
      key: 'chronicallyIll',
      sorter: (a: Member, b: Member) =>
        a.chronicallyIll === b.chronicallyIll ? 0 : a.chronicallyIll ? -1 : 1,
      render: (_: any, record: Member) =>
        record.chronicallyIll ? (
          <Tooltip title={record.chronicallyIllDescription}>yes</Tooltip>
        ) : (
          ''
        ),
    },
    {
      title: 'Disabled',
      dataIndex: 'disabled',
      key: 'disabled',
      sorter: (a: Member, b: Member) => (a.disabled === b.disabled ? 0 : a.disabled ? -1 : 1),
      render: (_: any, record: extendedMemberType) =>
        !record.disabled ? (
          ''
        ) : (
          <Tooltip title={record.disabilityList.join(', ')}>disabled</Tooltip>
        ),
    },
    {
      title: 'Ineligibility',
      dataIndex: 'ineligibleState',
      key: 'ineligibleState',
      sorter: (a: extendedMemberType, b: extendedMemberType) => {
        if (!(a.ineligibleState && b.ineligibleState)) return 0;
        return a.ineligibleState.localeCompare(b.ineligibleState);
      },
      render: (_: any, record: extendedMemberType) => {
        if (record.ineligibleState === 'household') {
          return <Tooltip title={`${record.criteriaMet!.join(', ')}`}>household</Tooltip>;
        } else if (record.ineligibleState === 'member') {
          return <Tooltip title={`${record.criteriaMet!.join(', ')}`}>member</Tooltip>;
        } else return '';
      },
    },
    {
      title: 'Actions',
      align: 'center' as 'center',
      key: 'action',
      render: (_: any, record: Member) => {
        // if (!!record.needApproval && householdData?.creatorId !== authUser.sub) {
        //   return (
        //     <Button
        //       style={
        //         householdData?.needApproval ? {} : { backgroundColor: 'green', color: 'yellow' }
        //       }
        //       disabled={householdData?.needApproval}
        //     >
        //       Need Approval
        //     </Button>
        //   );
        // }
        return (
          <>
            <Space size={10} style={{ display: 'flex', justifyContent: 'flex-end', gap: 5 }}>
              {/* ///// only admin can approve  */}
              {authUser.isAdmin && record.needApproval && (
                <Popconfirm
                  title={() => (
                    <p style={{ width: 300 }}>
                      You need to check the accurcacy of the data before approving a member. Have
                      you checked the data? Are you sure you want to approve this IDP member? <br />
                      (Name: {record.name})<br />
                      (Age: {record.age})<br />
                    </p>
                  )}
                  onConfirm={() => {
                    approveMember(record.id);
                  }}
                  okText='Yes'
                  cancelText='Cancel'
                  disabled={householdData?.needApproval === true}
                >
                  <Button
                    type='link'
                    style={{ width: 70 }}
                    ///// can be approved only when household has been approved
                    disabled={householdData?.needApproval === true}
                  >
                    Approve
                  </Button>
                </Popconfirm>
              )}
              {/* ///// members can be edited only after household has been approved */}
              {/* ///// members can be edited only by admin and editors */}
              {/* ///// both admin and editor can edit members only after member has been approved */}
              {/* ///// however editor can edit the member record even if s/he is not admin or editor */}
              <Button
                onClick={() => handleEditMember(record)}
                type='link'
                style={{ width: 40 }}
                disabled={
                  !(
                    (authUserIsMemberCreator(record) && !!record.needApproval) ||
                    (!householdData?.needApproval &&
                      !record.needApproval &&
                      (authUser.isAdmin || authUserIsEditor))
                  )
                }
              >
                Edit
              </Button>
              <Popconfirm
                title={`Are you sure you want to delete this member (${record.name})?`}
                onConfirm={() => handleDeleteMember(record.id)}
                disabled={
                  !(
                    (authUserIsMemberCreator(record) && !!record?.needApproval) ||
                    (!householdData?.needApproval &&
                      (authUser.isAdmin || (authUserIsEditor && !record.needApproval)))
                  )
                }
              >
                {/* ///// members can be deleted only after household has been approved */}
                {/* ///// members can be deleted only by admin and editors */}
                {/* ///// admin can delete members even before member has been approved */}
                {/* ///// editor can delete members only after member has been approved */}
                {/* ///// however editor can edit the member record even if s/he is not admin or editor */}

                <Button
                  type='link'
                  danger
                  disabled={
                    !(
                      (authUserIsMemberCreator(record) && !!record?.needApproval) ||
                      (!householdData?.needApproval &&
                        (authUser.isAdmin || (authUserIsEditor && record.needApproval !== true)))
                    )
                  }
                  style={{ width: 50, marginRight: 15 }}
                >
                  Delete
                </Button>
              </Popconfirm>
            </Space>
          </>
        );
      },
    },
  ];

  if (addingOrViewing === 'adding') return null;
  return (
    <div>
      <Space size={20} style={{ marginBottom: '6px' }}>
        <span>
          <strong>Camp:</strong> {householdData?.campName}
        </span>
        <span>
          <strong>Head:</strong> {householdData?.householdHead}
        </span>
        <span>
          <strong>Member #:</strong>{' '}
          {(householdData?.memberCount || 0) - (householdData?.membersNeedApproval || 0)}
        </span>
        <span>
          <strong>Need Approval:</strong> {householdData?.membersNeedApproval || 0}
        </span>
        <span>
          <strong>Eligibility:</strong> {householdData?.eligibility}
        </span>
        <span>
          <strong>Eligibile Members:</strong> {householdData?.eligibleMemberCount}
        </span>
        <Button
          type='primary'
          onClick={() => handleAddMember()}
          disabled={!!householdData?.needApproval && !authUserIsHouseholdCreator}
        >
          Add New Member
        </Button>
      </Space>
      <div style={{ padding: 10, backgroundColor: '#e5e5e5' }}>
        <Table
          columns={memberTableColumns}
          size='small'
          dataSource={members}
          rowKey='id'
          onRow={(record: Member) => {
            if (record.needApproval) {
              return {
                style: {
                  backgroundColor: 'khaki',
                },
              };
            } else {
              return {};
            }
          }}
          // pagination={{ pageSize: 7 }}
        />
      </div>
      <Drawer
        title={`${
          addEditMember === 'add'
            ? 'Adding Member'
            : addEditMember === 'edit'
            ? 'Editing Member Data'
            : 'Error!!!'
        }`}
        placement='left'
        getContainer={false}
        closable={false}
        // onClose={hideMemberDrawer}
        visible={!!addEditMember}
        style={{ position: 'absolute' }}
        bodyStyle={{ backgroundColor: 'lightblue' }}
        width={1000}
        footer={false}
      >
        <Form {...regularFormItemLayout} name='memberForm' form={form}>
          <Form.Item name='id' hidden>
            <Input />
          </Form.Item>
          <Row>
            <Col span={11} style={{ border: '1px solid white', padding: '16px 8px' }}>
              <Form.Item
                label='Name'
                name='name'
                rules={[{ required: true, message: 'Required!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item label='Age' name='age' rules={[{ required: true, message: 'Required!' }]}>
                <InputNumber min={0} max={120} style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                label='Gender'
                name='gender'
                rules={[{ required: true, message: 'Required!' }]}
              >
                <Select allowClear>
                  <Option key='MALE' value='MALE'>
                    Male
                  </Option>
                  <Option key='FEMALE' value='FEMALE'>
                    Female
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item
                label='Relationship to Head'
                name='relationshipToHead'
                rules={[{ required: true, message: 'Required!' }]}
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {Object.keys(RelationshipTypeMap).map((rtm) => (
                    <Option key={rtm} value={rtm}>
                      {RelationshipTypeMap[rtm]}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label='Earning' name='isMemberEarning' valuePropName='checked'>
                <Checkbox />
              </Form.Item>
              <Form.Item label='Chronically Ill' name='chronicallyIll' valuePropName='checked'>
                <Checkbox
                  checked={chronicIll}
                  onChange={(e) => {
                    setChronicIll(e.target.checked);
                  }}
                />
              </Form.Item>
              <Form.Item
                label='Describe chronically ill'
                name='chronicallyIllDescription'
                rules={[
                  {
                    required: chronicIll,
                    message: "Required if 'Chronically Ill' is checked!",
                  },
                ]}
                hidden={!chronicIll}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col
              span={6}
              style={{ border: '1px solid white', borderLeft: 'none', padding: '16px 8px' }}
            >
              <Form.Item
                {...checkboxOnlyLayout}
                label='Disabled'
                name='disabled'
                valuePropName='checked'
              >
                <Checkbox disabled />
              </Form.Item>
              <Form.Item
                wrapperCol={{ span: 20, offset: 2 }}
                style={{ marginTop: 8, marginBottom: 8 }}
              >
                <h5 style={{ borderBottom: '2px solid darkred', textAlign: 'center' }}>
                  Type(s) of disability
                </h5>
              </Form.Item>
              <Form.Item
                {...checkboxOnlyLayout}
                label='Vision'
                name='disabilityVision'
                valuePropName='checked'
              >
                <Checkbox onChange={checkDisability} />
              </Form.Item>
              <Form.Item
                {...checkboxOnlyLayout}
                label='Hearing'
                name='disabilityHearing'
                valuePropName='checked'
              >
                <Checkbox onChange={checkDisability} />
              </Form.Item>
              <Form.Item
                {...checkboxOnlyLayout}
                label='Mental'
                name='disabilityMental'
                valuePropName='checked'
              >
                <Checkbox onChange={checkDisability} />
              </Form.Item>
              <Form.Item
                {...checkboxOnlyLayout}
                label='Intellectural'
                name='disabilityIntellectual'
                valuePropName='checked'
              >
                <Checkbox onChange={checkDisability} />
              </Form.Item>
              <Form.Item
                {...checkboxOnlyLayout}
                // style={{ marginBottom: 0 }}
                label='Brain injury'
                name='disabilityBrainInjury'
                valuePropName='checked'
              >
                <Checkbox onChange={checkDisability} />
              </Form.Item>
              <Form.Item
                {...checkboxOnlyLayout}
                label='Autism'
                name='disabilityAutism'
                valuePropName='checked'
              >
                <Checkbox onChange={checkDisability} />
              </Form.Item>
              <Form.Item
                {...checkboxOnlyLayout}
                label='Physical'
                name='disabilityPhysical'
                valuePropName='checked'
              >
                <Checkbox onChange={checkDisability} />
              </Form.Item>
              <Form.Item
                {...checkboxOnlyLayout}
                label='Other'
                name='disabilityOther'
                valuePropName='checked'
              >
                <Checkbox
                  checked={disabledOther}
                  onChange={(e) => {
                    setDisabledOther(e.target.checked);
                    checkDisability();
                  }}
                />
              </Form.Item>
              <Form.Item
                label='Describe other:'
                name='disabilityOtherDescription'
                labelCol={{ span: 22, offset: 2 }}
                wrapperCol={{ span: 20, offset: 2 }}
                labelAlign='left'
                hidden={!disabledOther}
                rules={[
                  {
                    required: disabledOther,
                    message: "Required if 'Other' is checked!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col
              span={7}
              style={{ border: '1px solid white', borderLeft: 'none', padding: '16px 8px' }}
            >
              <Form.Item
                wrapperCol={{ span: 20, offset: 2 }}
                style={{ marginTop: 8, marginBottom: 8 }}
              >
                <h5 style={{ borderBottom: '2px solid darkred', textAlign: 'center' }}>
                  Ineligibility Criteria
                </h5>
              </Form.Item>
              <Form.Item
                {...checkboxOnlyLayoutLongLabel}
                label='Service personnel'
                name='servicePersonnel'
                valuePropName='checked'
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                {...checkboxOnlyLayoutLongLabel}
                label='Teacher / Nurse'
                name='teacherNurse'
                valuePropName='checked'
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                {...checkboxOnlyLayoutLongLabel}
                label='Chinese national'
                name='chinese'
                valuePropName='checked'
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                {...checkboxOnlyLayoutLongLabel}
                label='Boarding student'
                name='boardingStudent'
                valuePropName='checked'
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                {...checkboxOnlyLayoutLongLabel}
                label='Local villager'
                name='villagers'
                valuePropName='checked'
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                {...checkboxOnlyLayoutLongLabel}
                label='Local married to IDP'
                name='marriedToIdp'
                valuePropName='checked'
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                {...checkboxOnlyLayoutLongLabel}
                label='Priest / Pastor'
                name='priestPastor'
                valuePropName='checked'
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                {...checkboxOnlyLayoutLongLabel}
                label='Deregistered IDP'
                name='deregisteredIdp'
                valuePropName='checked'
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                {...checkboxOnlyLayoutLongLabel}
                label='Relocated IDP'
                name='relocatedIdp'
                valuePropName='checked'
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                {...checkboxOnlyLayoutLongLabel}
                label='From non-conflict area'
                name='fromNonConflictArea'
                valuePropName='checked'
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                {...checkboxOnlyLayoutLongLabel}
                label='Registered in non-conflict time'
                name='registeredDuringNonConflictTime'
                valuePropName='checked'
              >
                <Checkbox />
              </Form.Item>
            </Col>
            <Col span={24} style={{ display: 'flex', justifyContent: 'center', marginTop: 12 }}>
              <Space size={20}>
                <Form.Item>
                  <Button onClick={hideMemberDrawer}>Cancel</Button>
                </Form.Item>
                <Form.Item>
                  <Button onClick={handleMemberFormSave} type='primary'>
                    Save
                  </Button>
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
};

export default MembersTable;

const GenderTypeMap: { [key: string]: string } = {
  MALE: 'Male',
  FEMALE: 'Female',
};

const RelationshipTypeMap: { [key: string]: string } = {
  HEAD: 'Head',
  WIFE_OF_HEAD: 'Wife of Head',
  HUSBAND_OF_HEAD: 'Husband of Head',
  FATHER: 'Father',
  MOTHER: 'Mother',
  UNCLE: 'Uncle',
  AUNTY: 'Aunty',
  SON: 'Son',
  DAUGHTER: 'Daughter',
  NEPHEW: 'Nephew',
  NIECE: 'Niece',
  GRANDSON: 'Grandson',
  GRANDDAUGHTER: 'Granddaughter',
  BROTHER: 'Brother',
  SISTER: 'Sister',
  GRANDFATHER: 'Grandfather',
  GRANDMOTHER: 'Grandmother',
  BROTHER_IN_LAW: 'Brother-in-law',
  SISTER_IN_LAW: 'Sister-in-law',
  FATHER_IN_LAW: 'Father-in-law',
  MOTHER_IN_LAW: 'Mother-in-law',
  SON_IN_LAW: 'Son-in-law',
  DAUGHTER_IN_LAW: 'Daughter-in-law',
  OTHER: 'Other',
};
