// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const AreaType = {
  "GCA": "GCA",
  "NGCA": "NGCA"
};

const GenderType = {
  "MALE": "MALE",
  "FEMALE": "FEMALE"
};

const RelationshipType = {
  "HEAD": "HEAD",
  "WIFE_OF_HEAD": "WIFE_OF_HEAD",
  "HUSBAND_OF_HEAD": "HUSBAND_OF_HEAD",
  "FATHER": "FATHER",
  "MOTHER": "MOTHER",
  "UNCLE": "UNCLE",
  "AUNTY": "AUNTY",
  "SON": "SON",
  "DAUGHTER": "DAUGHTER",
  "NEPHEW": "NEPHEW",
  "NIECE": "NIECE",
  "GRANDSON": "GRANDSON",
  "GRANDDAUGHTER": "GRANDDAUGHTER",
  "BROTHER": "BROTHER",
  "SISTER": "SISTER",
  "GRANDFATHER": "GRANDFATHER",
  "GRANDMOTHER": "GRANDMOTHER",
  "BROTHER_IN_LAW": "BROTHER_IN_LAW",
  "SISTER_IN_LAW": "SISTER_IN_LAW",
  "FATHER_IN_LAW": "FATHER_IN_LAW",
  "MOTHER_IN_LAW": "MOTHER_IN_LAW",
  "SON_IN_LAW": "SON_IN_LAW",
  "DAUGHTER_IN_LAW": "DAUGHTER_IN_LAW",
  "OTHER": "OTHER"
};

const { GlobalSettings, Diocese, Camp, Ward, Room, Household, Member, Township } = initSchema(schema);

export {
  GlobalSettings,
  Diocese,
  Camp,
  Ward,
  Room,
  Household,
  Member,
  Township,
  AreaType,
  GenderType,
  RelationshipType
};