import React, { Dispatch, SetStateAction } from 'react';
import { DataStore, Hub, API } from 'aws-amplify';
import { Card, Spin } from 'antd';

import { useHistory } from 'react-router-dom';

// import { DataStoreContext } from '../../App';

import { Camp, Diocese, Township } from '../../models';
import styles from './CampList.module.css';
import { campInfo } from '../../App';

interface DisplayCamp {
  campID: string;
  campName: string;
  dioceseName: string | undefined;
  townshipName: string | undefined;
}

interface CampListPropsType {
  setCamp: Dispatch<SetStateAction<campInfo>>;
}

const CampList: React.FC<CampListPropsType> = ({ setCamp }) => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [campList, setCampList] = React.useState<Array<DisplayCamp>>();
  // const isDataStoreReady = React.useContext(DataStoreContext);

  const listener = React.useCallback(async (hubData: any) => {
    const { event } = hubData.payload;
    if (event === 'ready') {
      // console.log('data store is ready and now fetching camps');
      fetchedCamps();
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchedCamps();
    Hub.listen('datastore', listener);
    return () => Hub.remove('datastore', listener);
  }, [listener]);

  const fetchedCamps = async () => {
    const camps = await DataStore.query(Camp);
    const campList: DisplayCamp[] = [];

    for (const camp of camps) {
      const diocese = await DataStore.query(Diocese, camp.dioceseID);
      const township = await DataStore.query(Township, camp.townshipID);
      if (diocese && township) {
        campList.push({
          campID: camp.id,
          campName: camp.name,
          dioceseName: diocese?.name,
          townshipName: township?.name,
        });
      }
    }
    campList
      .sort((a, b) => a.campName!.localeCompare(b.campName!))
      .sort((a, b) => a.dioceseName!.localeCompare(b.dioceseName!));
    if (campList.length > 0) {
      setCampList(campList);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className={styles.spinContainer}>
        <Spin size='large' />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {campList &&
        campList.map((camp) => (
          <Card
            key={camp.campID}
            title={camp.campName}
            style={{ margin: 10, border: '0px solid green' }}
            hoverable
            headStyle={{
              backgroundColor: '#db6623',
              color: 'white',
              borderRadius: '5px 0px 0px 0px',
            }}
            bodyStyle={{
              width: 300,
              backgroundColor: '#dff5c6',
            }}
            onClick={() => {
              console.log(camp.campID);
              setCamp({ id: camp.campID, name: camp.campName });
              history.push(`/${camp.campName.replace(' ', '_').replace('#', '_')}`);
            }}
          >
            {/* <h1>{camp.name}</h1> */}
            <Card.Grid style={{ width: '100%', textAlign: 'left' }} hoverable={false}>
              Diocese: {camp.dioceseName}
            </Card.Grid>
            <Card.Grid style={{ width: '100%', textAlign: 'left' }} hoverable={false}>
              Township: {camp.townshipName}
            </Card.Grid>
          </Card>
        ))}
    </div>
  );
};

export default CampList;
