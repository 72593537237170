import { DataStore, Auth } from 'aws-amplify';
import React from 'react';
import { GlobalSettings, Household } from '../../models';

import styles from './Camp.module.css';
import { eligibilityListType } from './CampUtilities';
import Actions from './DisplayUnits/Actions';
import HouseholdList from './DisplayUnits/HouseholdList';
import Summary from './DisplayUnits/Summary';
// import RoomList from './DisplayUnits/RoomList';
import WardList from './DisplayUnits/WardList';
import { CognitoUserInterface } from '@aws-amplify/ui-components';

export interface HouseholdInterface extends Household {
  campName: string | undefined;
  wardNumber: string | undefined;
  roomNumber: number | undefined;
  householdHead: string | undefined;
  memberCount: number;
  membersNeedApproval: number;
  eligibility: 'yes' | 'no' | 'partial';
  eligibilityCriteriaMet: Array<eligibilityListType>;
  eligibleMemberCount: number;
}

interface CampStateType {
  globalSettings: GlobalSettings | undefined;
  camp_id: string | undefined;
  selectedWards: Array<string>;
  // selectedRooms: Array<string>;
  viewingWardsAndRooms: boolean;
  addingOrViewingHousehold: {
    addingOrViewing: 'adding' | 'viewing' | undefined;
    viewingHouseholdID: string | undefined;
  };
  selectedHouseholds: Array<HouseholdInterface>;
  refresh: boolean;
  authUser: {
    username: string | undefined;
    email: string | undefined;
    sub: string | undefined;
    groups: string[];
    isAdmin: boolean;
  };
}

const CampInitialState: CampStateType = {
  globalSettings: undefined,
  camp_id: undefined,
  selectedWards: [],
  // selectedRooms: [],
  viewingWardsAndRooms: false,
  addingOrViewingHousehold: {
    addingOrViewing: undefined,
    viewingHouseholdID: undefined,
  },
  selectedHouseholds: [],
  refresh: false,
  authUser: {
    username: undefined,
    email: undefined,
    sub: undefined,
    groups: [],
    isAdmin: false,
  },
};

export enum CampActionsTypes {
  SET_GLOBAL_SETTINGS,
  SET_CAMP_ID,
  SELECT_WARDS,
  VIEW_WARDS_AND_ROOMS,
  VIEW_HOUSEHOLD,
  ADD_HOUSEHOLD,
  DONE_ADD_VIEW_HOUSEHOLD,
  SELECT_HOUSEHOLDS,
  REFRESH,
  SET_AUTH_USER,
}

type CampActionsInterface =
  | { type: CampActionsTypes.SET_GLOBAL_SETTINGS; payload: GlobalSettings }
  | { type: CampActionsTypes.SET_CAMP_ID; payload: string }
  | { type: CampActionsTypes.SELECT_WARDS; payload: Array<string> }
  | { type: CampActionsTypes.VIEW_WARDS_AND_ROOMS; payload: boolean }
  | { type: CampActionsTypes.VIEW_HOUSEHOLD; payload: string | undefined }
  | { type: CampActionsTypes.ADD_HOUSEHOLD }
  | { type: CampActionsTypes.DONE_ADD_VIEW_HOUSEHOLD }
  | { type: CampActionsTypes.SELECT_HOUSEHOLDS; payload: Array<HouseholdInterface> }
  | { type: CampActionsTypes.REFRESH }
  | { type: CampActionsTypes.SET_AUTH_USER; payload: CognitoUserInterface };

const CampReducer = (state: CampStateType, action: CampActionsInterface): CampStateType => {
  switch (action.type) {
    case CampActionsTypes.SET_GLOBAL_SETTINGS:
      return { ...state, globalSettings: action.payload };
    case CampActionsTypes.SET_CAMP_ID:
      return { ...state, camp_id: action.payload };
    case CampActionsTypes.SELECT_WARDS:
      return { ...state, selectedWards: action.payload };
    // case CampActionsTypes.SELECT_ROOM:
    //   return { ...state, selectedRooms: action.payload };
    case CampActionsTypes.VIEW_WARDS_AND_ROOMS:
      return { ...state, viewingWardsAndRooms: action.payload };
    case CampActionsTypes.VIEW_HOUSEHOLD:
      return {
        ...state,
        addingOrViewingHousehold: {
          addingOrViewing: 'viewing' as 'viewing',
          viewingHouseholdID: action.payload,
        },
      };
    case CampActionsTypes.ADD_HOUSEHOLD:
      return {
        ...state,
        addingOrViewingHousehold: {
          addingOrViewing: 'adding' as 'adding',
          viewingHouseholdID: undefined,
        },
      };
    case CampActionsTypes.DONE_ADD_VIEW_HOUSEHOLD:
      return {
        ...state,
        addingOrViewingHousehold: {
          addingOrViewing: undefined,
          viewingHouseholdID: undefined,
        },
      };
    case CampActionsTypes.SELECT_HOUSEHOLDS:
      return {
        ...state,
        selectedHouseholds: action.payload,
      };
    case CampActionsTypes.REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };
    case CampActionsTypes.SET_AUTH_USER:
      const idTokenPayload = action.payload.signInUserSession.idToken.payload;
      return {
        ...state,
        authUser: {
          username: idTokenPayload['cognito:username'],
          email: idTokenPayload.email,
          sub: idTokenPayload.sub,
          groups: idTokenPayload['cognito:groups'],
          isAdmin: idTokenPayload['cognito:groups']?.includes('admin'),
        },
      };
    default:
      throw new Error('Error in Camp Reducer');
  }
};

type CampContextProps = {
  state: CampStateType;
  dispatch: React.Dispatch<CampActionsInterface> | undefined;
};

export const CampContext = React.createContext<CampContextProps>({
  state: CampInitialState,
  dispatch: undefined,
});

interface CampPropTypes {
  camp_id: string;
}

const Camp: React.FC<CampPropTypes> = ({ camp_id }) => {
  const [state, dispatch] = React.useReducer(CampReducer, CampInitialState);

  React.useEffect(() => {
    const setGlobalSettings = async () => {
      const settings = (await DataStore.query(GlobalSettings))[0];
      dispatch({ type: CampActionsTypes.SET_GLOBAL_SETTINGS, payload: settings });
    };

    setGlobalSettings();
  }, []);

  React.useEffect(() => {
    dispatch({ type: CampActionsTypes.SET_CAMP_ID, payload: camp_id });
  }, [camp_id]);

  React.useEffect(() => {
    const setAuthUser = async () => {
      const user: CognitoUserInterface = await Auth.currentAuthenticatedUser();
      // console.log(user.signInUserSession.idToken.payload['cognito:groups']);
      dispatch({ type: CampActionsTypes.SET_AUTH_USER, payload: user });
    };

    setAuthUser();
  }, [camp_id]);

  return (
    <CampContext.Provider value={{ state, dispatch }}>
      <div className={styles.container}>
        <div className={styles.left_bar}>
          <div className={styles.ward_bar}>
            <WardList />
          </div>
        </div>
        <div className={styles.right_bar}>
          <div className={styles.option_and_button}>
            <div className={styles.option_bar}>
              <Summary />
            </div>
            <div className={styles.button_bar}>
              <Actions />
            </div>
          </div>
          <div className={styles.main_display}>
            <div className={styles.household}>
              <HouseholdList />
            </div>
          </div>
        </div>
      </div>
    </CampContext.Provider>
  );
};

export default Camp;
