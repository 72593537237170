import { GlobalSettings, Member } from '../../models';
import { compact, intersection, min, union, uniq } from 'lodash';
import { exit } from 'process';

export type eligibilityListType =
  | 'servicePersonnel'
  | 'teacherNurse'
  | 'chinese'
  | 'boardingStudent'
  | 'villagers'
  | 'marriedToIdp'
  | 'priestPastor'
  | 'deregisteredIdp'
  | 'relocatedIdp'
  | 'fromNonConflictArea'
  | 'registeredDuringNonConflictTime';

const eligibilityList: Array<eligibilityListType> = [
  'servicePersonnel',
  'teacherNurse',
  'chinese',
  'boardingStudent',
  'villagers',
  'marriedToIdp',
  'priestPastor',
  'deregisteredIdp',
  'relocatedIdp',
  'fromNonConflictArea',
  'registeredDuringNonConflictTime',
];

export const eligibilityMap: { [key: string]: string } = {
  servicePersonnel: 'Service Personnel',
  teacherNurse: 'Teacher/Nurse',
  chinese: 'Chinese',
  boardingStudent: 'Boarding Student',
  villagers: 'Villagers',
  marriedToIdp: 'Married to IDP',
  priestPastor: 'Priest/Pastor',
  deregisteredIdp: 'De-registered IDP',
  relocatedIdp: 'Relocated IDP',
  fromNonConflictArea: 'From Non-conflict Area',
  registeredDuringNonConflictTime: 'Registered during non-conflict time',
};

export const checkHouseholdEligibility = (members: Member[], globalSettins: GlobalSettings) => {
  let hhIneligiblity = false;
  let memberIneligibility = false;
  let eligibleMemberCount = members.length;
  let criteriaMet: string[] = [];

  members.forEach((member) => {
    const membEligibility = checkMemberEligibility(member, globalSettins);
    if (!membEligibility) {
      return;
    }
    if (membEligibility.ineligibleState === 'household') {
      hhIneligiblity = true;
    } else if (membEligibility.ineligibleState === 'member') {
      memberIneligibility = true;
      eligibleMemberCount -= 1;
    }
    criteriaMet.push(...membEligibility.criteriaMet);
  });

  criteriaMet = uniq(criteriaMet);

  if (hhIneligiblity) {
    return {
      eligible: 'no',
      eligibleMemberCount: 0,
      criteriaMet,
    };
  } else if (memberIneligibility) {
    return {
      eligible: 'partial',
      eligibleMemberCount: eligibleMemberCount,
      criteriaMet,
    };
  } else {
    return {
      eligible: 'yes',
      eligibleMemberCount: members.length,
      criteriaMet,
    };
  }
};

export const checkMemberEligibility = (member: Member, globalSettins: GlobalSettings) => {
  const {
    householdIneligibility: householdIneligibilityList,
    individualIneligibility: individualIneligibilityList,
  } = globalSettins;
  if (!(householdIneligibilityList && individualIneligibilityList)) {
    return;
  }

  const memberIneligibilityList: Array<eligibilityListType> = [];
  eligibilityList.forEach((el) => {
    if (member[el]) {
      memberIneligibilityList.push(el);
    }
  });

  const hIneligibility = compact(intersection(householdIneligibilityList, memberIneligibilityList));
  const mIneligibility = compact(
    intersection(individualIneligibilityList, memberIneligibilityList)
  );
  const allMetCriteria = union(hIneligibility, mIneligibility).map((hm) => eligibilityMap[hm]);

  if (hIneligibility.length > 0) {
    return {
      ineligibleState: 'household',
      criteriaMet: allMetCriteria,
    };
  } else if (mIneligibility.length > 0) {
    return {
      ineligibleState: 'member',
      criteriaMet: allMetCriteria,
    };
  } else {
    return { ineligibleState: 'yes', criteriaMet: allMetCriteria };
  }
};

const campPermissionMap: { [key: string]: string } = {
  'f0d9bff9-e2b5-582c-af1b-fecfb65f0a8a': 'camp-saw-zam',
  'ac90432f-b9b8-50a1-91a9-31adeff6e149': 'camp-je-yang',
  '78765f9a-8d6b-5f06-8f6d-6b57fef54256': 'camp-bum-tsit-pa',
  '3b11e3ae-e368-5a76-aea5-1c8421e7b961': 'camp-pang-wa',
  '324e204c-4781-5cd6-afd6-e4493ad6fb2c': 'camp-pa-kahtawng',
  '3e599d80-d079-5520-b2b1-f4d24eb99d09': 'camp-bp8',
  '0ba0ab8e-13a4-59d9-8a19-d263f36959b9': 'camp-bp6',
  '92f7c4b6-715c-53a2-aaac-63ad80a3ec86': 'camp-n-khawng-pa',
  'aef7d05e-c376-51c2-9a23-41a615422f33': 'camp-dum-bung',
  '6ad260d6-21f4-5748-a1b6-f23c2d8a9539': 'camp-lana-zup-ja',
};

const diocesePermissionMap: { [key: string]: string } = {
  'f0d9bff9-e2b5-582c-af1b-fecfb65f0a8a': 'diocese-myitkyina',
  'ac90432f-b9b8-50a1-91a9-31adeff6e149': 'diocese-bhamo',
  '78765f9a-8d6b-5f06-8f6d-6b57fef54256': 'diocese-bhamo',
  '3b11e3ae-e368-5a76-aea5-1c8421e7b961': 'diocese-myitkyina',
  '324e204c-4781-5cd6-afd6-e4493ad6fb2c': 'diocese-bhamo',
  '3e599d80-d079-5520-b2b1-f4d24eb99d09': 'diocese-myitkyina',
  '0ba0ab8e-13a4-59d9-8a19-d263f36959b9': 'diocese-myitkyina',
  '92f7c4b6-715c-53a2-aaac-63ad80a3ec86': 'diocese-bhamo',
  'aef7d05e-c376-51c2-9a23-41a615422f33': 'diocese-bhamo',
  '6ad260d6-21f4-5748-a1b6-f23c2d8a9539': 'diocese-bhamo',
};

export const getReaders = (campID: string) => {
  return [campPermissionMap[campID]];
};

export const getEditors = (campID: string) => {
  return ['dioceseNational', diocesePermissionMap[campID]];
};

type disabilityType =
  | 'disabilityVision'
  | 'disabilityHearing'
  | 'disabilityMental'
  | 'disabilityIntellectual'
  | 'disabilityBrainInjury'
  | 'disabilityAutism'
  | 'disabilityPhysical'
  | 'disabilityOther';

const disabilityList: Array<disabilityType> = [
  'disabilityVision',
  'disabilityHearing',
  'disabilityMental',
  'disabilityIntellectual',
  'disabilityBrainInjury',
  'disabilityAutism',
  'disabilityPhysical',
  'disabilityOther',
];

const disabilityTypeMap: { [key: string]: string } = {
  disabilityVision: 'Vision',
  disabilityHearing: 'Hearing',
  disabilityMental: 'Mental',
  disabilityIntellectual: 'Intellectual',
  disabilityBrainInjury: 'Brain Injury',
  disabilityAutism: 'Autism',
  disabilityPhysical: 'Physical',
  disabilityOther: 'Other',
};

export const getMemberDisabilityLIst = (member: Member) => {
  const memberDisabilityList: Array<string> = [];
  disabilityList.forEach((dl) => {
    if (member[dl]) {
      memberDisabilityList.push(disabilityTypeMap[dl]);
    }
  });
  return memberDisabilityList;
};
