import React from 'react';
import { DataStore, Auth } from 'aws-amplify';

import { Household, Township, Ward, Room, Diocese, Camp } from '../../../models';
import { CampActionsTypes, CampContext, HouseholdInterface } from '../Camp';

import { ReactComponent as CheckBoxOutlineChecked } from '../../../assets/icons/check_box-outline-24px.svg';
import { ReactComponent as CheckBoxOutlineBlank } from '../../../assets/icons/check_box_outline_blank-24px.svg';

import { getReaders, getEditors } from '../CampUtilities';

import {
  Form,
  Row,
  Col,
  Input,
  InputNumber,
  Space,
  Button,
  Select,
  Checkbox,
  Popconfirm,
} from 'antd';
import Styles from './HouseholdForm.module.css';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
const { Option } = Select;

// interface HouseholdFormProps {
//   viewingHouseholdID: string | undefined;
//   modelVisible: boolean;
// }

const HouseholdForm = () => {
  const [householdData, setHouseholdData] = React.useState<HouseholdInterface>();
  const [editing, setEditing] = React.useState(false);
  const [townships, setTownships] = React.useState<Array<Township>>([]);
  const [wards, setWards] = React.useState<Array<Ward>>([]);
  const [rooms, setRooms] = React.useState<Array<Room>>([]);

  const [selectedWardID, setSelectedWardID] = React.useState<string>();

  const { state, dispatch } = React.useContext(CampContext);
  const {
    refresh,
    selectedHouseholds,
    addingOrViewingHousehold,
    camp_id,
    selectedWards,
    authUser,
  } = state;

  const authUserIsCreator =
    authUser.sub &&
    householdData &&
    householdData.creatorId &&
    authUser.sub === householdData.creatorId;

  const { addingOrViewing, viewingHouseholdID } = addingOrViewingHousehold;

  const [form] = Form.useForm();

  React.useEffect(() => {
    let isSubscribed = true;
    const fetchBaseData = async () => {
      if (!camp_id) {
        return;
      }
      try {
        const fetchedTownships = await DataStore.query(Township);
        const fetchedWards = await DataStore.query(Ward, (w) => w.campID('eq', camp_id));
        const fetchedRooms = (await DataStore.query(Room)).filter((r) =>
          fetchedWards.map((fw) => fw.id).includes(r.wardID)
        );
        if (isSubscribed) {
          setTownships(fetchedTownships);
          setWards(fetchedWards);
          setRooms(fetchedRooms);
        }
      } catch (err) {
        console.error('Data Store error in Edit Household');
      }
    };

    fetchBaseData();

    return function cleanup() {
      isSubscribed = false;
    };
  }, [selectedHouseholds, refresh, camp_id, dispatch]);

  React.useEffect(() => {
    let isSubscribed = true;
    if (!viewingHouseholdID) {
      return;
    }
    try {
      const fetchedHousehold = selectedHouseholds.find((hh) => hh.id === viewingHouseholdID);
      if (!fetchedHousehold) {
        if (dispatch) {
          dispatch({
            type: CampActionsTypes.DONE_ADD_VIEW_HOUSEHOLD,
          });
        }
        return;
      }
      if (isSubscribed) {
        setHouseholdData(fetchedHousehold);
      }
    } catch (err) {
      console.error('Data Store error in Edit Household');
    }

    return function cleanup() {
      isSubscribed = false;
    };
  }, [viewingHouseholdID, selectedHouseholds, dispatch]);

  // console.log(viewingHouseholdID);

  React.useEffect(() => {
    if (addingOrViewing === undefined) {
      setEditing(false);
    } else if (addingOrViewing === 'adding') {
      setHouseholdData(undefined);
      setSelectedWardID(undefined);
      setEditing(true);
    }
    // if addingOrViewing === 'viewing' then pass;
  }, [addingOrViewing]);

  React.useEffect(() => {
    if (addingOrViewing === 'viewing' && editing) {
      setSelectedWardID(householdData?.wardID!);
      form.setFieldsValue({
        foodRationNumber: householdData?.foodRationNumber,
        wardID: householdData?.wardID,
        roomID: householdData?.roomID,
        townshipOfOriginID: householdData?.townshipOfOriginID,
        currentRation: householdData?.currentRation,
        staySince: householdData?.staySince,
        contactPersonName: householdData?.contactPersonName,
        contactPhoneNumber: householdData?.contactPhoneNumber,
        monthlyIncome: householdData?.monthlyIncome,

        alt_income_sources_small_business: householdData?.alt_income_sources_small_business,
        alt_income_sources_loan_bank: householdData?.alt_income_sources_loan_bank,
        alt_income_sources_rent_assets: householdData?.alt_income_sources_rent_assets,
        alt_income_sources_taxi: householdData?.alt_income_sources_taxi,
        alt_income_sources_crop: householdData?.alt_income_sources_crop,
        alt_income_sources_skilled_labor: householdData?.alt_income_sources_skilled_labor,
        alt_income_sources_license_butcher: householdData?.alt_income_sources_license_butcher,

        alt_income_sources_livestock: householdData?.alt_income_sources_livestock,
        alt_income_sources_agri_wager: householdData?.alt_income_sources_agri_wager,
        alt_income_sources_non_agri_wager: householdData?.alt_income_sources_non_agri_wager,
        alt_income_sources_handicrafts: householdData?.alt_income_sources_handicrafts,
        alt_income_sources_mining: householdData?.alt_income_sources_mining,
        alt_income_sources_forest_product: householdData?.alt_income_sources_forest_product,
        alt_income_sources_remittances_in_country:
          householdData?.alt_income_sources_remittances_in_country,
        alt_income_sources_remittance_abroad: householdData?.alt_income_sources_remittance_abroad,
        alt_income_sources_gift: householdData?.alt_income_sources_gift,
        alt_income_sources_loan_friends_family:
          householdData?.alt_income_sources_loan_friends_family,
        alt_income_sources_loan_lender: householdData?.alt_income_sources_loan_lender,
        alt_income_sources_loan_saving_group: householdData?.alt_income_sources_loan_saving_group,
        alt_income_sources_job: householdData?.alt_income_sources_job,
        alt_income_sources_re_sale: householdData?.alt_income_sources_re_sale,
        alt_income_sources_domestic_work: householdData?.alt_income_sources_domestic_work,
        alt_income_sources_interest: householdData?.alt_income_sources_interest,
        alt_income_sources_others: householdData?.alt_income_sources_others,
        alt_income_sources_others_description: householdData?.alt_income_sources_others_description,
      });
    } else {
      form.resetFields();
    }
  }, [editing, form, householdData, addingOrViewing]);

  const handleSave = async () => {
    const values = form.getFieldsValue();

    if (addingOrViewing === 'adding') {
      if (!camp_id) return;
      const activeCamp = await DataStore.query(Camp, camp_id);
      if (!activeCamp) return;
      const activeDiocese = await DataStore.query(Diocese, activeCamp.dioceseID);
      if (!activeDiocese) return;
      const diocese_id = activeDiocese.id;

      const newHH = await DataStore.save(
        new Household({
          campID: camp_id,
          dioceseID: diocese_id,
          readers: getReaders(camp_id),
          editors: getEditors(camp_id),

          foodRationNumber: values.foodRationNumber,
          wardID: values.wardID,
          roomID: values.roomID,
          townshipOfOriginID: values.townshipOfOriginID,
          currentRation: values.currentRation,
          staySince: values.staySince,
          contactPersonName: values.contactPersonName,
          contactPhoneNumber: values.contactPhoneNumber,
          monthlyIncome: values.monthlyIncome,
          alt_income_sources_small_business: values.alt_income_sources_small_business,
          alt_income_sources_loan_bank: values.alt_income_sources_loan_bank,
          alt_income_sources_rent_assets: values.alt_income_sources_rent_assets,
          alt_income_sources_taxi: values.alt_income_sources_taxi,
          alt_income_sources_crop: values.alt_income_sources_crop,
          alt_income_sources_skilled_labor: values.alt_income_sources_skilled_labor,
          alt_income_sources_license_butcher: values.alt_income_sources_license_butcher,

          alt_income_sources_livestock: values.alt_income_sources_livestock,
          alt_income_sources_agri_wager: values.alt_income_sources_agri_wager,
          alt_income_sources_non_agri_wager: values.alt_income_sources_non_agri_wager,
          alt_income_sources_handicrafts: values.alt_income_sources_handicrafts,
          alt_income_sources_mining: values.alt_income_sources_mining,
          alt_income_sources_forest_product: values.alt_income_sources_forest_product,
          alt_income_sources_remittances_in_country:
            values.alt_income_sources_remittances_in_country,
          alt_income_sources_remittance_abroad: values.alt_income_sources_remittance_abroad,
          alt_income_sources_gift: values.alt_income_sources_gift,
          alt_income_sources_loan_friends_family: values.alt_income_sources_loan_friends_family,
          alt_income_sources_loan_lender: values.alt_income_sources_loan_lender,
          alt_income_sources_loan_saving_group: values.alt_income_sources_loan_saving_group,
          alt_income_sources_job: values.alt_income_sources_job,
          alt_income_sources_re_sale: values.alt_income_sources_re_sale,
          alt_income_sources_domestic_work: values.alt_income_sources_domestic_work,
          alt_income_sources_interest: values.alt_income_sources_interest,
          alt_income_sources_others: values.alt_income_sources_others,
          alt_income_sources_others_description: values.alt_income_sources_others_description,
          /////
          needApproval: true,
          creatorId: authUser.sub,
          creatorEmail: authUser.email,
        })
      );

      if (dispatch) {
        const wardIDSet = new Set(selectedWards);
        wardIDSet.add(newHH.wardID);
        dispatch({ type: CampActionsTypes.SELECT_WARDS, payload: Array.from(wardIDSet) });
        setTimeout(() => {
          dispatch({ type: CampActionsTypes.VIEW_HOUSEHOLD, payload: newHH.id });
        }, 2500);
      }
    } else if (addingOrViewing === 'viewing') {
      if (!viewingHouseholdID) {
        return;
      }
      const originalHH = await DataStore.query(Household, viewingHouseholdID);
      if (!originalHH) {
        console.error('No household has been fetched.');
        return;
      }

      const abcdefg = await DataStore.save(
        Household.copyOf(originalHH, (updated) => {
          // updated.foodRationNumber = parseInt(values.foodRationNumber);
          updated.foodRationNumber = values.foodRationNumber;
          updated.wardID = values.wardID;
          updated.roomID = values.roomID;
          updated.townshipOfOriginID = values.townshipOfOriginID;
          updated.currentRation = values.currentRation;
          updated.staySince = values.staySince;
          updated.contactPersonName = values.contactPersonName;
          updated.contactPhoneNumber = values.contactPhoneNumber;
          updated.monthlyIncome = values.monthlyIncome;
          updated.alt_income_sources_small_business = values.alt_income_sources_small_business;
          updated.alt_income_sources_loan_bank = values.alt_income_sources_loan_bank;
          updated.alt_income_sources_rent_assets = values.alt_income_sources_rent_assets;
          updated.alt_income_sources_taxi = values.alt_income_sources_taxi;
          updated.alt_income_sources_crop = values.alt_income_sources_crop;
          updated.alt_income_sources_skilled_labor = values.alt_income_sources_skilled_labor;
          updated.alt_income_sources_license_butcher = values.alt_income_sources_license_butcher;

          updated.alt_income_sources_livestock = values.alt_income_sources_livestock;
          updated.alt_income_sources_agri_wager = values.alt_income_sources_agri_wager;
          updated.alt_income_sources_non_agri_wager = values.alt_income_sources_non_agri_wager;
          updated.alt_income_sources_handicrafts = values.alt_income_sources_handicrafts;
          updated.alt_income_sources_mining = values.alt_income_sources_mining;
          updated.alt_income_sources_forest_product = values.alt_income_sources_forest_product;
          updated.alt_income_sources_remittances_in_country =
            values.alt_income_sources_remittances_in_country;
          updated.alt_income_sources_remittance_abroad =
            values.alt_income_sources_remittance_abroad;
          updated.alt_income_sources_gift = values.alt_income_sources_gift;
          updated.alt_income_sources_loan_friends_family =
            values.alt_income_sources_loan_friends_family;
          updated.alt_income_sources_loan_lender = values.alt_income_sources_loan_lender;
          updated.alt_income_sources_loan_saving_group =
            values.alt_income_sources_loan_saving_group;
          updated.alt_income_sources_job = values.alt_income_sources_job;
          updated.alt_income_sources_re_sale = values.alt_income_sources_re_sale;
          updated.alt_income_sources_domestic_work = values.alt_income_sources_domestic_work;
          updated.alt_income_sources_interest = values.alt_income_sources_interest;
          updated.alt_income_sources_others = values.alt_income_sources_others;
          updated.alt_income_sources_others_description =
            values.alt_income_sources_others_description;
        })
      );

      console.log({ abcdefg });

      if (dispatch) {
        const wardIDSet = new Set(selectedWards);
        wardIDSet.add(values.wardID);
        dispatch({ type: CampActionsTypes.SELECT_WARDS, payload: Array.from(wardIDSet) });
      }
    }

    // console.log(values);
    if (dispatch) {
      dispatch({ type: CampActionsTypes.REFRESH });
    }
    setEditing(false);
  };

  const handleCancel = () => {
    if (addingOrViewing === 'adding') {
      if (dispatch) {
        dispatch({ type: CampActionsTypes.DONE_ADD_VIEW_HOUSEHOLD });
      }
      setEditing(false);
    } else if (addingOrViewing === 'viewing') {
      setEditing(false);
    }
  };

  const approveHousehold = async (householdID: string) => {
    const household = await DataStore.query(Household, householdID);
    if (!household) {
      console.error('No household found.');
      return;
    }

    await DataStore.save(
      Household.copyOf(household, (updated) => {
        updated.needApproval = false;
        updated.approverId = authUser.sub;
        updated.approverEmail = authUser.email;
      })
    );

    if (dispatch) {
      dispatch({ type: CampActionsTypes.REFRESH });
    }
  };

  return (
    <>
      <Space size={20} style={{ marginBottom: '6px' }}>
        <span>
          <strong>Camp:</strong> {householdData?.campName}
        </span>
        <span>
          <strong>Head:</strong> {householdData?.householdHead}
        </span>
        <span>
          <strong>Member #:</strong>{' '}
          {(householdData?.memberCount || 0) - (householdData?.membersNeedApproval || 0)}
        </span>
        <span>
          <strong>Need Approval:</strong> {householdData?.membersNeedApproval || 0}
        </span>
        <span>
          <strong>Eligibility:</strong> {householdData?.eligibility}
        </span>
        <span>
          <strong>Eligibile Members:</strong> {householdData?.eligibleMemberCount}
        </span>
        {editing ? (
          <Space size={20}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleSave}>Save</Button>
          </Space>
        ) : (
          <Button
            onClick={() => setEditing(true)}
            disabled={!!householdData?.needApproval && !authUserIsCreator}
          >
            Edit
          </Button>
        )}
        {!!householdData?.needApproval && authUser.isAdmin && (
          <Popconfirm
            title={() => (
              <p style={{ width: 300 }}>
                You need to check the accurcacy of the data before approving a household. Have you
                checked the data? Are you sure you want to approve this househol? <br />
                (Ration number: {householdData.foodRationNumber})
              </p>
            )}
            onConfirm={() => {
              approveHousehold(householdData.id);
            }}
            okText='Yes'
            cancelText='Cancel'
          >
            <Button>Approve Household</Button>
          </Popconfirm>
        )}
      </Space>
      <Form form={form} className={Styles.HouseholdForm}>
        <Row>
          <Col span={8} className={Styles.LeftColumn}>
            <h3>Basic Household Data</h3>
            <FormInput
              name='foodRationNumber'
              label='Food Ration Number'
              data={householdData?.foodRationNumber}
              rules={[
                {
                  required: true,
                  message: 'Required',
                },
              ]}
              editing={editing}
            >
              <InputNumber
                min={0}
                max={999999}
                // formatter={(value: any) => String(parseInt(value))}
                style={{ width: '100%' }}
              />
            </FormInput>
            <FormInput
              name='wardID'
              label='Ward'
              data={wards.find((w) => w.id === householdData?.wardID)?.wardNumber}
              rules={[
                {
                  required: true,
                  message: 'Required',
                },
              ]}
              editing={editing}
            >
              <Select
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) => {
                  form.setFieldsValue({ roomID: undefined });
                  form.validateFields(['roomID']);
                  setSelectedWardID(value as string | undefined);
                }}
              >
                {wards
                  .sort((a, b) => a.wardNumber.localeCompare(b.wardNumber))
                  .map((ward) => (
                    <Option key={ward.id} value={ward.id}>
                      {ward.wardNumber}
                    </Option>
                  ))}
              </Select>
            </FormInput>

            <FormInput
              name='roomID'
              label='Room'
              data={rooms.find((r) => r.id === householdData?.roomID)?.roomNumber}
              rules={[{ required: true, message: 'Required' }]}
              editing={editing}
            >
              <Select
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {rooms
                  .filter((room) => room.wardID === selectedWardID)
                  .sort((a, b) => a.roomNumber - b.roomNumber)
                  .map((room) => (
                    <Option key={room.id} value={room.id}>
                      {String(room.roomNumber)}
                    </Option>
                  ))}
              </Select>
            </FormInput>

            <FormInput
              name='townshipOfOriginID'
              label='Township of Origin'
              data={townships.find((tsp) => tsp.id === householdData?.townshipOfOriginID)?.name}
              editing={editing}
            >
              <Select
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {townships
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  .map((township) => (
                    <Option key={township.id} value={township.id}>
                      {township.name}
                    </Option>
                  ))}
              </Select>
            </FormInput>

            <FormInput
              name='staySince'
              label='Arrive to camp in (year)'
              data={householdData?.staySince}
              editing={editing}
            >
              <InputNumber
                min={2010}
                max={new Date().getFullYear()}
                // formatter={(value: any) => String(parseInt(value))}
                style={{ width: '100%' }}
              />
            </FormInput>

            <FormInput
              name='currentRation'
              label='Current Ration (%)'
              data={householdData?.currentRation}
              editing={editing}
            >
              <InputNumber
                min={0}
                max={100}
                // formatter={(value: any) => String(parseInt(value))}
                style={{ width: '100%' }}
              />
            </FormInput>

            <FormInput
              name='contactPersonName'
              label='Contact Person'
              data={householdData?.contactPersonName}
              editing={editing}
            >
              <Input />
            </FormInput>

            <FormInput
              name='contactPhoneNumber'
              label='Contact Phone Number'
              data={householdData?.contactPhoneNumber}
              editing={editing}
            >
              <Input />
            </FormInput>

            <FormInput
              name='monthlyIncome'
              label='Monthly Income'
              data={householdData?.monthlyIncome}
              editing={editing}
            >
              <InputNumber
                // formatter={(value: any) => String(parseInt(value))}
                style={{ width: '100%' }}
              />
            </FormInput>
          </Col>
          <Col span={8} className={Styles.MiddleColumn}>
            <h3>Income Sources (Businesses / Shops)</h3>
            <FormInput
              name='alt_income_sources_small_business'
              label='Small Business'
              data={householdData?.alt_income_sources_small_business}
              isCheckbox
              editing={editing}
            >
              <Checkbox />
            </FormInput>
            <FormInput
              name='alt_income_sources_loan_bank'
              label='Loan from Bank'
              data={householdData?.alt_income_sources_loan_bank}
              isCheckbox
              editing={editing}
            >
              <Checkbox />
            </FormInput>
            <FormInput
              name='alt_income_sources_rent_assets'
              label='Rent Assets'
              data={householdData?.alt_income_sources_rent_assets}
              isCheckbox
              editing={editing}
            >
              <Checkbox />
            </FormInput>
            <FormInput
              name='alt_income_sources_taxi'
              label='Taxi'
              data={householdData?.alt_income_sources_taxi}
              isCheckbox
              editing={editing}
            >
              <Checkbox />
            </FormInput>
            <FormInput
              name='alt_income_sources_crop'
              label='Selling Crops'
              data={householdData?.alt_income_sources_crop}
              isCheckbox
              editing={editing}
            >
              <Checkbox />
            </FormInput>
            <FormInput
              name='alt_income_sources_skilled_labor'
              label='Skilled Labor'
              data={householdData?.alt_income_sources_skilled_labor}
              isCheckbox
              editing={editing}
            >
              <Checkbox />
            </FormInput>
            <FormInput
              name='alt_income_sources_license_butcher'
              label='Licensed Butcher'
              data={householdData?.alt_income_sources_license_butcher}
              isCheckbox
              editing={editing}
            >
              <Checkbox />
            </FormInput>
          </Col>
          <Col span={8} className={Styles.RightColumn}>
            <h3>Income Sources (Other Incomes)</h3>
            <FormInput
              name='alt_income_sources_livestock'
              label='Selling Livestock'
              data={householdData?.alt_income_sources_livestock}
              isCheckbox
              editing={editing}
            >
              <Checkbox />
            </FormInput>

            <FormInput
              name='alt_income_sources_agri_wager'
              label='Agri Wager'
              data={householdData?.alt_income_sources_agri_wager}
              isCheckbox
              editing={editing}
            >
              <Checkbox />
            </FormInput>

            <FormInput
              name='alt_income_sources_non_agri_wager'
              label='Non Agri Wager'
              data={householdData?.alt_income_sources_non_agri_wager}
              isCheckbox
              editing={editing}
            >
              <Checkbox />
            </FormInput>

            <FormInput
              name='alt_income_sources_handicrafts'
              label='Selling Handicrafts'
              data={householdData?.alt_income_sources_handicrafts}
              isCheckbox
              editing={editing}
            >
              <Checkbox />
            </FormInput>

            <FormInput
              name='alt_income_sources_mining'
              label='Mining'
              data={householdData?.alt_income_sources_mining}
              isCheckbox
              editing={editing}
            >
              <Checkbox />
            </FormInput>

            <FormInput
              name='alt_income_sources_forest_product'
              label='Forest Product'
              data={householdData?.alt_income_sources_forest_product}
              isCheckbox
              editing={editing}
            >
              <Checkbox />
            </FormInput>

            <FormInput
              name='alt_income_sources_remittances_in_country'
              label='Remittance (in-country)'
              data={householdData?.alt_income_sources_remittances_in_country}
              isCheckbox
              editing={editing}
            >
              <Checkbox />
            </FormInput>

            <FormInput
              name='alt_income_sources_remittance_abroad'
              label='Remittance (abroad)'
              data={householdData?.alt_income_sources_remittance_abroad}
              isCheckbox
              editing={editing}
            >
              <Checkbox />
            </FormInput>

            <FormInput
              name='alt_income_sources_gift'
              label='Gift from Relatives/Neighbors'
              data={householdData?.alt_income_sources_gift}
              isCheckbox
              editing={editing}
            >
              <Checkbox />
            </FormInput>

            <FormInput
              name='alt_income_sources_loan_friends_family'
              label='Loan from Friends/Family'
              data={householdData?.alt_income_sources_loan_friends_family}
              isCheckbox
              editing={editing}
            >
              <Checkbox />
            </FormInput>

            <FormInput
              name='alt_income_sources_loan_lender'
              label='Loan from Money Lender'
              data={householdData?.alt_income_sources_loan_lender}
              isCheckbox
              editing={editing}
            >
              <Checkbox />
            </FormInput>

            <FormInput
              name='alt_income_sources_loan_saving_group'
              label='Loan from Saving Group'
              data={householdData?.alt_income_sources_loan_saving_group}
              isCheckbox
              editing={editing}
            >
              <Checkbox />
            </FormInput>

            <FormInput
              name='alt_income_sources_job'
              label='Job'
              data={householdData?.alt_income_sources_job}
              isCheckbox
              editing={editing}
            >
              <Checkbox />
            </FormInput>

            <FormInput
              name='alt_income_sources_re_sale'
              label='Reselling in-kind aids'
              data={householdData?.alt_income_sources_re_sale}
              isCheckbox
              editing={editing}
            >
              <Checkbox />
            </FormInput>

            <FormInput
              name='alt_income_sources_domestic_work'
              label='Domestic Works'
              data={householdData?.alt_income_sources_domestic_work}
              isCheckbox
              editing={editing}
            >
              <Checkbox />
            </FormInput>

            <FormInput
              name='alt_income_sources_interest'
              label='Interest from Lending'
              data={householdData?.alt_income_sources_interest}
              isCheckbox
              editing={editing}
            >
              <Checkbox />
            </FormInput>
            <FormInput
              name='alt_income_sources_others'
              label='Other Source of Income'
              data={householdData?.alt_income_sources_others}
              isCheckbox
              editing={editing}
            >
              <Checkbox />
            </FormInput>

            <FormInput
              name='alt_income_sources_others_description'
              label='Other (Description)'
              data={householdData?.alt_income_sources_others_description}
              editing={editing}
            >
              <Input />
            </FormInput>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default HouseholdForm;

type FormInputPropType = {
  name: string;
  label: string;
  data: string | number | boolean | undefined;
  editing: boolean;
  isCheckbox?: boolean;
  rules?: any;
  children: any;
};

const FormInput: React.FC<FormInputPropType> = ({
  name,
  label,
  data,
  editing,
  isCheckbox,
  rules,
  children,
}) => {
  return (
    <Row>
      <Col span={isCheckbox ? 20 : 12}>
        <span className={Styles.DataDisplayLabel}>{label} :</span>
      </Col>
      <Col span={isCheckbox ? 4 : 12}>
        {editing ? (
          <Form.Item
            name={name}
            className={Styles.TextBox}
            rules={rules}
            valuePropName={isCheckbox ? 'checked' : undefined}
          >
            {children}
          </Form.Item>
        ) : !isCheckbox ? (
          <span className={Styles.DataDisplay}>{data}</span>
        ) : (
          <span
            className={Styles.DataDisplay}
            style={{
              paddingLeft: '0px',
              backgroundColor: 'inherit',
            }}
          >
            {data === true ? (
              <CheckBoxOutlineChecked width='21' height='22' />
            ) : (
              <CheckBoxOutlineBlank width='22' height='22' />
            )}
          </span>
        )}
      </Col>
    </Row>
  );
};
