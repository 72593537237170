import React from 'react';
import { CampContext } from '../Camp';
import { uniq } from 'lodash';

import { Statistic, Row, Col } from 'antd';

const Summary = () => {
  const { state } = React.useContext(CampContext);
  const { selectedHouseholds } = state;

  const approvedSelectedHouseholds = selectedHouseholds.filter((hh) => hh.needApproval !== true);

  const wardCount = uniq(approvedSelectedHouseholds.map((hh) => hh.wardID)).length;
  const roomCount = uniq(approvedSelectedHouseholds.map((hh) => hh.roomID)).length;
  const hhCount = approvedSelectedHouseholds.length;
  const memberCount = approvedSelectedHouseholds.reduce(
    (total, hh) => total + hh.memberCount - hh.membersNeedApproval,
    0
  );
  const fullEligibleHouseholdCounts = approvedSelectedHouseholds.filter(
    (hh) => hh.eligibility === 'yes'
  ).length;
  const partialEligibleHouseholdCounts = approvedSelectedHouseholds.filter(
    (hh) => hh.eligibility === 'partial'
  ).length;
  const ineligibleHouseholdCounts = approvedSelectedHouseholds.filter(
    (hh) => hh.eligibility === 'no'
  ).length;
  const eligibleMemberCount = approvedSelectedHouseholds.reduce(
    (total, hh) => total + hh.eligibleMemberCount,
    0
  );

  return (
    <div>
      <Row gutter={16}>
        <Col span={6}>
          <Statistic title='Ward Count' value={wardCount} />
        </Col>
        <Col span={6}>
          <Statistic title='Room Count' value={roomCount} />
        </Col>
        <Col span={6}>
          <Statistic title='Household Count' value={hhCount} />
        </Col>
        <Col span={6}>
          <Statistic title='Member Count' value={memberCount} />
        </Col>
        <Col span={6}>
          <Statistic title='Full Eligible Households' value={fullEligibleHouseholdCounts} />
        </Col>
        <Col span={6}>
          <Statistic title='Partial Eligible Households' value={partialEligibleHouseholdCounts} />
        </Col>
        <Col span={6}>
          <Statistic title='Ineligible Households' value={ineligibleHouseholdCounts} />
        </Col>
        <Col span={6}>
          <Statistic title='Total Eligible Members' value={eligibleMemberCount} />
        </Col>
      </Row>
    </div>
  );
};

export default Summary;
