import React from 'react';
import { Button, Table } from 'antd';

import { DataStore } from 'aws-amplify';
import { Ward, Room, Household } from '../../../models';
import { CampActionsTypes, CampContext } from '../Camp';

import { countBy } from 'lodash';
import WardsAndRooms from '../Modals/WardsAndRooms';

const columns = [
  {
    title: 'Wards',
    dataIndex: 'wardNumber',
  },
  {
    title: 'Rooms #',
    dataIndex: 'roomCount',
    align: 'right' as 'left' | 'right' | 'center',
    render: (text: string) => {
      return <span style={{ paddingRight: '10px' }}>{text}</span>;
    },
  },
  {
    title: 'HH #',
    dataIndex: 'householdCount',
    align: 'right' as 'left' | 'right' | 'center',
    render: (text: string) => {
      return <span style={{ paddingRight: '15px' }}>{text}</span>;
    },
  },
];

interface WardInterface {
  campID: string;
  wardID: string;
  wardNumber: string;
  roomCount: number;
  householdCount: number;
}

const WardList = () => {
  const [wards, setWards] = React.useState<Array<WardInterface>>([]);
  // const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);

  const { state, dispatch } = React.useContext(CampContext);
  const { camp_id, selectedWards, refresh } = state;

  React.useEffect(() => {
    let isSubscribed = true;
    const fetchWards = async () => {
      if (!camp_id) {
        return;
      }
      try {
        const fetchedHouseholds = await DataStore.query(Household, (hh) =>
          hh.campID('eq', camp_id)
        );
        const householdsGroupByWard = countBy(fetchedHouseholds, (hh) => hh.wardID);

        const fetchedWards = await DataStore.query(Ward, (w) => w.campID('eq', camp_id));
        const wardIDList = fetchedWards.map((fw) => fw.id);

        const fetchedRooms = (await DataStore.query(Room)).filter((r) =>
          wardIDList.includes(r.wardID)
        );
        const roomCountsByWard = countBy(fetchedRooms, (fr) => fr.wardID);

        fetchedWards.sort((a, b) => a.wardNumber.localeCompare(b.wardNumber));
        const wardArray: Array<WardInterface> = [];
        fetchedWards.forEach((ward) => {
          wardArray.push({
            campID: ward.campID,
            wardID: ward.id,
            wardNumber: ward.wardNumber,
            roomCount: roomCountsByWard[ward.id],
            householdCount: householdsGroupByWard[ward.id],
          });
        });
        if (isSubscribed) {
          setWards(wardArray);
        }
      } catch (error) {
        console.error('Data Store error in Ward List.');
      }
    };
    fetchWards();

    return function cleanup() {
      isSubscribed = false;
    };
  }, [camp_id, refresh]);

  const onSelectChange = async (selectedKeys: any) => {
    if (dispatch) {
      dispatch({ type: CampActionsTypes.SELECT_WARDS, payload: selectedKeys });
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedWards,
    onChange: onSelectChange,
  };

  const handleViewWardsAndRooms = () => {
    if (dispatch) {
      dispatch({
        type: CampActionsTypes.VIEW_WARDS_AND_ROOMS,
        payload: true,
      });
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Button onClick={handleViewWardsAndRooms}>Manage Wards and Rooms</Button>
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns}
        size='small'
        dataSource={wards}
        rowKey='wardID'
        pagination={false}
        scroll={{ y: 'calc(100vh - 10rem )' }}
      />
      <WardsAndRooms />
    </div>
  );
};

export default WardList;
