import React from 'react';
import { DataStore } from 'aws-amplify';

import { Household, Room, Ward, Member, Camp } from '../../../models';
import { CampActionsTypes, CampContext, HouseholdInterface } from '../Camp';
import ViewHousehold from '../Modals/ViewHousehold';

import { Table, Button, Menu, Dropdown, Input, Space, message, Popconfirm } from 'antd';
import Styles from './HouseholdLIst.module.css';
import { checkHouseholdEligibility, eligibilityListType } from '../CampUtilities';

import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/lib/table';

const HouseholdList = () => {
  const { state, dispatch } = React.useContext(CampContext);
  const [loading, setLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [searchedColumn, setSearchedColumn] = React.useState('');
  const {
    camp_id,
    selectedWards,
    selectedHouseholds,
    addingOrViewingHousehold,
    refresh,
    globalSettings,
    authUser,
  } = state;

  React.useEffect(() => {
    const fetchHHs = async () => {
      if (!camp_id) {
        return;
      }
      try {
        setLoading(true);

        const campName = (await DataStore.query(Camp, camp_id))?.name;

        const fetchedHouseholds = (
          await DataStore.query(Household, (hh) => hh.campID('eq', camp_id))
        ).filter((hh) => selectedWards.includes(hh.wardID));

        const fetchedWards = (await DataStore.query(Ward, (w) => w.campID('eq', camp_id))).filter(
          (w) => selectedWards.includes(w.id)
        );
        // console.log({ fetchedWards });

        const fetchedRooms = (await DataStore.query(Room)).filter((r) =>
          fetchedWards.map((fw) => fw.id).includes(r.wardID)
        );
        // console.log({ fetchedRooms });
        // console.log({ fetchedHouseholds });
        const fetchedMembers = (
          await DataStore.query(Member, (mb) => mb.campID('eq', camp_id))
        ).filter((mb) => fetchedHouseholds.map((fh) => fh.id).includes(mb.householdID));

        const householdList: HouseholdInterface[] = [];

        fetchedHouseholds.forEach((hh) => {
          const hhMembers = fetchedMembers.filter((fm) => fm.householdID === hh.id);
          const approvedHhMembers = hhMembers.filter((fm) => fm.needApproval !== true);
          const hhHead = hhMembers.find((hhm) => hhm.relationshipToHead === 'HEAD')?.name;
          const memberCount = hhMembers.length;
          const membersNeedApproval = hhMembers.filter((hhm) => hhm.needApproval === true).length;
          const hhEligibility = checkHouseholdEligibility(approvedHhMembers, globalSettings!);
          // console.log({ hh });
          // console.log(hh.roomID);
          householdList.push({
            ...hh,
            campName: campName,
            wardNumber: fetchedWards.find((fw) => fw.id === hh.wardID)!.wardNumber,
            roomNumber: fetchedRooms.find((fr) => fr.id === hh.roomID)!.roomNumber,
            householdHead: hhHead,
            memberCount: memberCount,
            membersNeedApproval,
            eligibility: hhEligibility.eligible as 'yes' | 'no' | 'partial',
            eligibilityCriteriaMet: hhEligibility.criteriaMet as eligibilityListType[],
            eligibleMemberCount: hhEligibility.eligibleMemberCount,
          });
        });
        // console.log(selectedWards);
        // console.log(fetchedHouseholds);
        // console.log(householdList);
        householdList
          .sort((a, b) => {
            if (a.roomNumber && b.roomNumber) {
              return a.roomNumber - b.roomNumber;
            } else {
              return 0;
            }
          })
          .sort((a, b) => {
            if (a.wardNumber && b.wardNumber) {
              return a.wardNumber.localeCompare(b.wardNumber);
            } else {
              return 0;
            }
          });

        // console.log(householdList.length);
        if (dispatch) {
          dispatch({ type: CampActionsTypes.SELECT_HOUSEHOLDS, payload: householdList });
        }
      } catch (err) {
        setLoading(false);
        console.log(err);
        console.error('Data Store error in Household List.');
      }
      setLoading(false);
    };
    fetchHHs();
  }, [camp_id, selectedWards, selectedWards.length, refresh, globalSettings, dispatch]);
  // console.log({ selectedWards });

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
          {/* <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText('');
  };

  const handleDeleteHousehold = async (householdID: string) => {
    const householdToDelete = await DataStore.query(Household, householdID);
    if (!householdToDelete) return;

    const members = await DataStore.query(Member, (m) => m.householdID('eq', householdID));
    if (members.length > 0) {
      message.error(
        `This household has ${members.length} members. You need to delete members first in order to delete this household.`
      );
      return;
    }

    DataStore.delete(householdToDelete);
    if (dispatch) dispatch({ type: CampActionsTypes.REFRESH });
  };

  const columns: Array<ColumnType<HouseholdInterface>> = [
    {
      title: 'Ward',
      dataIndex: 'wardNumber',
      render: (text: string) => {
        return <span style={{ paddingLeft: '10px' }}>{text}</span>;
      },
      sorter: (a: any, b: any) => a.wardNumber.localeCompare(b.wardNumber),
    },
    {
      title: 'Room',
      dataIndex: 'roomNumber',
      // render: (text: string) => {
      //   return <span style={{ paddingLeft: '10px' }}>{text}</span>;
      // },
      sorter: (a: any, b: any) => a.roomNumber - b.roomNumber,
      ...getColumnSearchProps('roomNumber'),
    },
    { title: 'Phone', dataIndex: 'contactPhoneNumber' },
    {
      title: 'Head',
      dataIndex: 'householdHead',
      ...getColumnSearchProps('householdHead'),
    },
    {
      title: 'Members #',
      dataIndex: 'memberCount',
      align: 'right' as 'left' | 'right' | 'center',
      render: (text, record) => {
        return (
          <span style={{ paddingRight: '15px' }}>
            {record.memberCount - record.membersNeedApproval}
          </span>
        );
      },
    },
    {
      title: 'Ration No.',
      dataIndex: 'foodRationNumber',
      align: 'right' as 'left' | 'right' | 'center',
      // render: (text: string) => {
      //   return <span style={{ paddingRight: '15px' }}>{text}</span>;
      // },
      sorter: (a: any, b: any) => a.foodRationNumber - b.foodRationNumber,
      ...getColumnSearchProps('foodRationNumber'),
    },
    {
      title: 'Ration',
      dataIndex: 'currentRation',
      align: 'right' as 'left' | 'right' | 'center',
      render: (text: string) => {
        return <span style={{ paddingRight: '15px' }}>{text} %</span>;
      },
      sorter: (a: any, b: any) => a.currentRation - b.currentRation,
    },
    {
      title: 'Eligible',
      dataIndex: 'eligibility',
      align: 'center' as 'left' | 'right' | 'center',
      sorter: (a: any, b: any) => a.eligibility.localeCompare(b.eligibility),
    },
    {
      title: 'Elig. Memb.',
      dataIndex: 'eligibleMemberCount',
      align: 'center' as 'left' | 'right' | 'center',
    },
    {
      title: 'Monthly $',
      dataIndex: 'monthlyIncome',
      align: 'right' as 'left' | 'right' | 'center',
      render: (text: string) => {
        return <span style={{ paddingRight: '10px' }}>{text}</span>;
      },
      sorter: (a: any, b: any) => a.monthlyIncome - b.monthlyIncome,
    },
    {
      title: 'Actions',
      align: 'center' as 'left' | 'right' | 'center',
      key: 'action',
      render: (text: any, record: any) => (
        <Dropdown overlay={() => actionMenu(record)} placement='topRight'>
          <Button type='link'>Actions</Button>
        </Dropdown>
      ),
    },
  ];

  const actionMenu = (household: HouseholdInterface) => (
    <Menu>
      <Menu.Item>
        <h4 className={Styles.menuHeader}>Ration #: {household.foodRationNumber}</h4>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          if (dispatch) {
            dispatch({
              type: CampActionsTypes.VIEW_HOUSEHOLD,
              payload: household.id,
            });
          }
        }}
      >
        <span>View household details</span>
      </Menu.Item>
      <Menu.Item>
        <Popconfirm
          title={`Are you sure you want to delete this household (Ration#: ${household.foodRationNumber})?`}
          onConfirm={() => handleDeleteHousehold(household.id)}
          okText='Yes'
          cancelText='No'
          disabled={!authUser.isAdmin}
        >
          {/* <span style={{ display: 'inline-block', width: '100%' }}>Delete household</span> */}
          <Button
            type='text'
            danger
            style={{ margin: 0, padding: 0, textAlign: 'left' }}
            disabled={!authUser.isAdmin}
          >
            Delete household
          </Button>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Table
        columns={columns}
        size='small'
        dataSource={selectedHouseholds}
        rowKey='id'
        pagination={{ showSizeChanger: true }}
        scroll={{ y: 'calc(100vh - 26rem )' }}
        loading={!addingOrViewingHousehold.addingOrViewing && loading}
        onRow={(record: HouseholdInterface) => {
          if (record.needApproval) {
            return {
              style: { backgroundColor: 'khaki' },
            };
          } else if (record.membersNeedApproval > 0) {
            return {
              style: { backgroundColor: 'lightgreen' },
            };
          } else {
            return {};
          }
        }}
      />
      <ViewHousehold />
    </div>
  );
};

export default HouseholdList;
